import React from 'react';

const Step3 = () => {
  return (
    <div className="self-tutorial-text">
      <p>Przeczytaj tekst, jeśli napotkasz na nieznane słowo, kliknij na nie a dodasz go do interaktywnego słownika. Słowa możliwe do dodania są <u>podkreślone</u></p>
      <p>Dodaj przynajmniej 4 słowa do interaktywnego słownika aby przejść dalej</p>
    </div>
  )
}

export default Step3;
