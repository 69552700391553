import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import { login } from '../shared/actions/authActions';
import validateInput from '../utils/clientValidation';
import parseQuery from '../utils/parseQuery';
import Navigation from '../shared/components/navigation/Navigation.jsx';

import '../shared/styles/Forms.scss';
import './LoginPage.scss';

let recaptchaInstance;

const resetRecaptcha = () => {
  recaptchaInstance.reset();
};

export class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        email: '',
        password: ''
      },
      isValid: false,
      isLoading: false,
      email: undefined,
      password: undefined,
      recaptchaVerified: true,
      loginAttempt: 0,
      newPassword: false
    };
  }
  componentDidMount() {
    window.addEventListener('keypress', this.onKeyPress);
    const { newPassword } = parseQuery(this.props.location.search);
    if(newPassword) {
      this.setState({
        newPassword
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('keypress', this.onKeyPress, false);
  }
  onKeyPress = e => {
    const key = e.which || e.keyCode;
    if (key === 13) {
      this.onSubmit();
    }
  }
  onChange = e => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.isValid();
    });
  }
  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    this.setState({ errors, isValid });
    return isValid;
  }
  onSubmit = e => {
    e && e.preventDefault();
    if(!this.isValid())
      return;

    this.setState({ errors: {}, isLoading: true });
    this.props.login(this.state)
      .then(() => {
        this.props.history.push('/course');
      })
      .catch(response => {
        const status = response.status;
        if(status === 422) { // invalid input data
          response.data.errors.forEach(error => {
            this.setState(prevState => ({
              errors: {
                ...prevState.errors,
                [error.param]: error.msg
              },
              isLoading: false
            }));
          });
        } else if(status === 400 && response.data.error.type === 'not-verified') { // email is not verified
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              email: response.data.error.message
            },
            isLoading: false
          }));
        } else { // user not found or email / password combination is invalid
          this.setState({
            loginAttempt: this.state.loginAttempt + 1,
            errors: {
              password: response.data.error.message
            },
            isLoading: false
          }, () => {
            if(this.state.loginAttempt >= 3) {
              this.setState({ recaptchaVerified: false });
              resetRecaptcha();
            }
          });
        }
      });
  }
  verifyCallback = (res) => {
    if(res) {
      this.setState({recaptchaVerified: true});
    }
  }
  render() {
    const { errors, recaptchaVerified, isLoading, isValid, newPassword } = this.state;
    return (
      <div className='wrapper'>

        <Navigation history={this.props.history} />

        <form className='form' onSubmit={e => e.preventDefault()}>

          <div className='form-header'>
            <h3>
              {
                newPassword ?
                  'Hasło zostało zmienione, możesz się teraz zalogować'
                  :
                  'Zaloguj się na swoje konto'
              }
            </h3>
          </div>

          <div className="input-container">
            <div
              className={classNames('username', {'input-error': errors && errors['email']})}>
              <i className="fa fa-user"></i>
              <input
                onChange={this.onChange}
                className='credential-input'
                type="email"
                name="email"
                placeholder="Twoj email"
                aria-label="Email"/>
            </div>
            {
              errors && errors['email']
              && <div className="error-message">{ errors['email'] }</div>
            }
            <div
              className={classNames('password', {'input-error': errors && errors['password']})}>
              <i className="fa fa-unlock"></i>
              <input
                onChange={this.onChange}
                className="credential-input"
                autoComplete="on"
                type="password"
                name="password"
                placeholder="Twoje haslo"
                aria-label="Password"/>
            </div>
            {
              errors && errors['password']
              && <div className="error-message">{ errors['password'] }</div>
            }
          </div>

          <button type="submit" name=""
            className={classNames(
              'action-button',
              {'action-button-disabled': !recaptchaVerified || isLoading || !isValid}
            )}
            disabled={!recaptchaVerified || isLoading || !isValid}
            onClick={this.onSubmit}>
            {
              isLoading ?
                <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                :
                'ZALOGUJ'
            }
          </button>

          <div className="password-panel">
            <Link to="/login/reset-password" className="forgot-password">
              Nie pamiętasz hasła?
            </Link>
          </div>

          <div className='recaptcha-container'>
            {
              this.state.loginAttempt >= 3 &&
                <Recaptcha ref={e => recaptchaInstance = e}
                  sitekey="6LfvSIYUAAAAAF1FJhYQZcVKys2_aH3rEwSZCU9v"
                  verifyCallback={this.verifyCallback} />
            }
          </div>

          {
            this.state.loginAttempt < 3 &&
            <div className="horizontal-line" style={{top: -40}}></div>
          }

          <div className="register-panel">
            Nie masz jeszcze konta?
            <Link to="register" className="register">
              <span> Zarejestruj się </span>
            </Link>
            <i className='fa fa-user-edit'></i>
          </div>

        </form>
      </div>
    );
  }
}

export default withRouter(connect(null, {login})(LoginPage));
