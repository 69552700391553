import React from 'react';
import { Link } from 'react-router-dom';

import ProfileButton from '../../../shared/components/profileButton/ProfileButton.jsx';

const LandingNavigationTabs = props => {
  return (
    <div className="landing-navigation-tabs">
      {
        props.isAuthenticated ?
          <Link to="course">
            <ProfileButton isSticky={props.isSticky} shouldHideArrow={true} />
          </Link>
          :
          <Link to="login">
            <div className="landing-navigation-tab landing-navigation-tab-login">ZALOGUJ</div>
          </Link>
          //<div className="login-lock">
          //<i className="fa fa-lock"></i>
          //<div className="navigation-tab navigation-tab-login">ZALOGUJ</div>
          //</div>
      }
      <div className="landing-navigation-tab" onClick={() => props.select('faq')}>FAQ</div>
      <div className="landing-navigation-tab" onClick={() => props.select('about')}>O MNIE</div>
      <div className="landing-navigation-tab" onClick={() => props.select('course')}>KURS</div>
      <div className="landing-navigation-tab" onClick={() => props.select('offer')}>OFERTA</div>
    </div>
  );
};

export default LandingNavigationTabs;

