import React from 'react';

import '../../utils/arrayUnique';
import './Offer.scss';
import OfferOption from './offerOption/OfferOption.jsx';
const basic = ['Autorskie teksty z najważniejszą terminologią medyczną', 'Interaktywny słownik użytkownika', 'Bazowy słownik z terminologią medyczną', 'Korzystna cena: kurs 30-dniowy - 89zł, kurs 60-dniowy - 129zł'];
const advanced = ['Autorskie dyktanda medyczne, nagrane przez rodowitych Polaków', 'Dostęp do tekstów dyktand oraz możliwość samokontroli', 'Interaktywny słownik użytkownika', 'Bazowy słownik z terminologią medyczną'];
let basicCopy = Object.assign([], basic);
basicCopy = basicCopy.slice(0, -1);
const pack = [...basicCopy, ...advanced];

const listElements = {
  basic,
  advanced,
  pack: pack.unique()
};

const Offer = () => {
  return (
    <section aria-label="Offer" id="offer">
      <div className="offer-content">
        <OfferOption
          title="PODSTAWY TERMINOLOGII"
          subtitle="Podstawowa terminologia medyczna z zakresu anatomii, fizjologii, objawów chorób i najczęstszych jednostek chorobowych"
          listElements={listElements.basic}
          price="Kup teraz od 89"
          caption="* Z uwagi na trudność polskiej terminologii medycznej zalecany jest poziom znajomości języka polskiego na poziomie A2. "
          back='Kurs "Podstawy Terminologii" to kurs dla osób, które zaczynają uczyć się terminologii medycznej. Opracowałam 14 podstawowych bloków tematycznych:'
          backList={[
            'Układ narządów ruchu',
            'Układ trawienny',
            'Układ oddechowy',
            'Układ moczowo-płciowy',
            'Układ krążenia',
            'Krew i jej składniki',
            'Układ nerwowy',
            'Układ narządów zmysłów',
            'Powłoka wspólna i onkologia',
            'Chirurgia i gabinet zabiegowy',
            'Choroby zakaźne',
            'Położnictwo i ginekologia',
            'Psychiatria',
            'Gruczoły dokrewne'
          ]}
        />
        <OfferOption
          title="TERMINOLOGIA ZAAWANSOWANA"
          subtitle="Medyczny język polski, którym lekarze posługują się na co dzień, ten kurs przygotuje Cię do egzaminu ze znajomości języka polskiego dla lekarzy"
          listElements={listElements.advanced}
          price="JUŻ WKRÓTCE"
          caption="* Z uwagi na trudność polskiej terminologii medycznej zalecany jest poziom znajomości języka polskiego na poziomie A2-B1 oraz znajomość podstawowej terminologii medycznej."
          back='Kurs "Terminologia Zaawansowana" ma na celu pomóc ci w przygotowaniu do egzaminu ze znajomości języka polskiego dla lekarzy. Opracowałam najbardziej popularne tematy z różnych dziedzin medycyny.
            Kurs składa się z autorskich dyktand na różne tematy medyczne, nagrane przez rodowitych Polaków z dobrą dykcją. Kurs składa się z 30 bloków tematycznych' />
        <OfferOption
          title="PAKIET KURSÓW"
          subtitle="Kompleksowa nauka terminologii medycznej w języku polskim dla lekarzy. Pakiet zawiera oba kursy"
          listElements={listElements.pack}
          oldPrice="168"
          price="JUŻ WKRÓTCE"
          caption="* Z uwagi na trudność polskiej terminologii medycznej zalecany jest znajomość języka polskiego na poziomie A2-B1."
          back='Pakiet Kursów zawiera zarówno kurs "Podstawy Terminologii" jak i kurs "Terminologia Zaawansowana", dzięki czemu posiądziesz kompleksową wiedzę na temat terminologii medycznej w języku polskim.' />
      </div>
    </section>
  );
};

export default Offer;

