import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ProfileButton from 'shared/components/profileButton/ProfileButton.jsx';
import ProfileMenu from './profileMenu/ProfileMenu.jsx';

const CourseNavigationTabs = props => {
  return (
    <div className="course-navigation-tabs">
      {
        (props.pathname === '/course' || props.pathname === '/course/shop' || props.pathname === '/course/admin' ||  props.pathname === '/course/account') &&
          <div className="course-navigation-tab course-navigation-tab-logout">
            <ProfileButton
              isSticky={true}
              toogleProfileMenu={props.toogleProfileMenu}
              shouldShowProfileMenu={props.shouldShowProfileMenu} />
            <TransitionGroup className='course-elements-container'>
              <CSSTransition
                key={props.shouldShowProfileMenu}
                timeout={400}
                classNames='profile-menu-slide-top'>
                <div className="absolute-wrapper">
                  {
                    props.shouldShowProfileMenu &&
                    <ProfileMenu
                      isAdmin={props.isAdmin}
                      logout={props.logout}
                      toogleProfileMenu={props.toogleProfileMenu} />
                  }
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
      }
      {
        props.pathname === '/demo' &&
        <Link to="/">
          <div className="course-navigation-tab course-navigation-tab-logout">
            <p> ZAKOŃCZ DEMO </p>
            <i className='fa fa-power-off'></i>
          </div>
        </Link>
      }
    </div>
  );
};

export default CourseNavigationTabs;
