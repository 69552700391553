import React, { Component } from 'react';
import classNames from 'classnames';

import './ResetPasswordNew.scss';
import parseQuery from '../../../utils/parseQuery.js';
import axiosDyktando from '../../../utils/axiosDyktando.js';
import validateInput from '../../../utils/clientValidation';
import Navigation from '../../../shared/components/navigation/Navigation.jsx';

export class ResetPasswordNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        password: '',
        passwordConfirmation: ''
      },
      isValid: false,
      password: undefined,
      passwordConfirmation: undefined,
      pending: false,
      success: false,
      error: ''
    };
  }
  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    this.setState({ errors, isValid });
    return isValid;
  }
  onInputChange = e => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.isValid();
    });
  }
  onSubmit = e => {
    e.preventDefault();
    if(this.isValid()) {
      this.setState({
        pending: true,
        success: false
      });
      const { password } = this.state;
      const { userId, resetPasswordToken } = parseQuery(this.props.location.search);
      // console.log(this.props.authState.user);
      // const { _id, tokens } = this.props.authState.user;
      // alert(_id, tokens);
      axiosDyktando.post('/api/auth/new-password', {
        userId,
        resetPasswordToken,
        password
      }).then(res => {
        console.log(res);
        this.setState({
          pending: false,
          success: true
        });
        this.props.history.push('/login?newPassword=true');
      }).catch(err => {
        console.log(err);
        this.setState({
          pending: false,
          success: false
        });
      });
    }
  }
  render() {
    const { errors, isValid, pending } = this.state;
    return (
      <div className='wrapper'>
        <Navigation history={this.props.history} />
        <div className="form">
          <h2> Odzyskiwanie hasła </h2>
          <h3> Podaj nowe hasło </h3>
          <div className="input-container">
            <div className="password">
              <i className="fa fa-unlock"></i>
              <input
                onChange={this.onInputChange}
                className="credential-input"
                type="password"
                name="password"
                placeholder="Nowe haslo"
                aria-label="Password"/>
            </div>
            {
              errors && errors['password']
              && <div className="error-message">{ errors['password'] }</div>
            }

            <div className="password">
              <i className="fa fa-unlock"></i>
              <input
                onChange={this.onInputChange}
                className="credential-input"
                type="password"
                name="passwordConfirmation"
                placeholder="Powtórz haslo"
                aria-label="Password"/>
            </div>
            {
              errors && errors['passwordConfirmation']
              && <div className="error-message">{ errors['passwordConfirmation'] }</div>
            }
          </div>

          <button type="submit" name=""
            className={classNames('action-button', {'action-button-disabled': pending || !isValid})}
            disabled={pending || !isValid}
            onClick={this.onSubmit}>
            {
              pending ?
                <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                :
                'ZMIEŃ HASŁO'
            }
          </button>
        </div>
      </div>
    );
  }
}

export default ResetPasswordNew;
