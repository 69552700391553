import React from 'react';

const Step5 = () => {
  return (
    <div className="self-tutorial-text">
      <p>
        Odpowiedz na 4 pytania znajdujące się pod tekstem aby usystematyzować zdobytą wiedzę.
      </p>
      <p>
        Gratulacje, właśnie ukończyłeś samouczek!
      </p>
    </div>
  )
}

export default Step5;
