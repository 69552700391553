import React, { Component } from 'react';
import axios from 'axios';

import './Newsletter.scss';

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isLoading: false,
      errorMessage: ''
    };
  }
  componentDidMount() {
    this.props.newsletterPoppedUp();
  }
  handleChange = (event) => {
    this.setState({email: event.target.value});
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true
    });
    axios.post('/api/newsletter/subscribe', {
      email: this.state.email
    }).then(() => {
      this.setState({
        isLoading: false,
        errorMessage: ''
      });
      this.props.toogleModal();
    }).catch(err => {
      if(err.response.data.title === 'Member Exists') {
        this.setState({
          errorMessage: 'Ten adres istnieje już w bazie.',
          isLoading: false
        });
      } else {
        this.setState({
          errorMessage: 'Ups.. coś poszło nie tak, spróbuj ponownie później.',
          isLoading: false
        });
      }
    });
  }
  render() {
    // <p className="discount">Otrzymasz <span>10% zniżki*</span> na kurs "Podstawy terminologii"</p>
    const { isLoading } = this.state;
    return (
      <div className="newsletter-modal">
        <div className="newsletter">
          <div className="close-container" onClick={this.props.toogleModal}>
            <i className="fa fa-times"></i>
          </div>
          <h2>ZAPISZ SIĘ DO NEWSLETTERA</h2>
          <p>Jako pierwszy/sza dowiesz się o:</p>
          <ul>
            <li>
              <i className="fa-li fa fa-check"></i>
              wersji demo kursu "Terminologia zaawansowana"
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              starcie sprzedaży kursu "Terminologia zaawansowana"
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              promocjach i kuponach
            </li>
          </ul>
          <div className="email-container">
            <label htmlFor="email">Twój adres email:</label>
            <div className="email-submit">
              <form onSubmit={this.handleSubmit} >
                <input type="email" id="email" size="30" required
                  value={this.state.email} onChange={this.handleChange} />
                <button type="submit">
                  {
                    isLoading ?
                      <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                      :
                      'ZAPISZ SIĘ'
                  }
                </button>
              </form>
              {
                this.state.errorMessage !== '' &&
                <p style={{color: '#e74c3c'}}> { this.state.errorMessage } </p>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Newsletter;

