import React from 'react';
import { Link } from 'react-router-dom';

import './Homepage.scss';
import Modal from '../modal/Modal.jsx';
import Newsletter from './newsletter/Newsletter.jsx';

const Homepage = props => {
  return (
    <section aria-label="Homepage">
      <div className="homepage-content">
        <div className="homepage-background"></div>
        <div className="homepage-overlay"></div>
        <div className="homepage-main">
          <div className="homepage-head">
            <h1 className="homepage-header-h1"> CHCESZ LECZYĆ W POLSCE? </h1>
            <h2 className="homepage-header-h2"> Kursy Online z terminologii medycznej języka polskiego dla lekarzy<span></span></h2>
          </div>
          {
            props.isAuthenticated ?
              <Link to="/course">
                <button className="homepage-button">
                  ROZPOCZNIJ KURS
                </button>
              </Link>
              :
              <Link to="/demo">
                <button className="homepage-button">
                  TESTUJ BEZPŁATNIE
                </button>
              </Link>
          }
          <div className="homepage-arows" onClick={() => window.scrollTo({top: window.innerHeight, left: 0, behavior: 'smooth'})}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {
          !props.isNewsletterOpen &&
          <button className="newsletter-button" onClick={props.toogleModal} >
            <i className="fa fa-3x fa-envelope"></i>
          </button>
        }
      </div>
      {
        props.isNewsletterOpen &&
        <Modal>
          {
            <Newsletter toogleModal={props.toogleModal} newsletterPoppedUp={props.newsletterPoppedUp} />
          }
        </Modal>
      }
    </section>
  );
};

export default Homepage;
