import { SET_COURSES, SET_CURRENT_COURSE } from '../../constants/actionTypes';

export const setCourses = (courses) => ({
  type: SET_COURSES,
  courses
});

export const setCurrentCourse = (currentCourse) => ({
  type: SET_CURRENT_COURSE,
  currentCourse
});
