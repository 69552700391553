import React from 'react';
import { NavLink } from 'react-router-dom';

import './ProfileMenu.scss';

const ProfileMenu = (props) => {
  return (
    <div className="profile-menu">
      <ul className="profile-menu-dropdown" onClick={props.toogleProfileMenu} >
        {
          props.isAdmin &&
          <li>
            <div>
              <NavLink activeClassName='is-active' to="/course/admin">Admin</NavLink>
              <i className="fa fa-angle-right"></i>
            </div>
          </li>
        }
        <li>
          <div>
            <NavLink activeClassName='is-active' exact to="/course">Kurs</NavLink>
            <i className="fa fa-angle-right"></i>
          </div>
        </li>
        <li>
          <div>
            <NavLink activeClassName='is-active' to="/course/shop">Sklep</NavLink>
            <i className="fa fa-angle-right"></i>
          </div>
        </li>
        <li>
          <div>
            <NavLink activeClassName='is-active' to="/course/account">Konto</NavLink>
            <i className="fa fa-angle-right"></i>
          </div>
        </li>
        <li onClick={props.logout}>Wyloguj</li>
      </ul>
    </div>
  )
}

export default ProfileMenu;
