import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { logout } from '../shared/actions/authActions';

export default function(ComposedComponent, authorizationLevel = 'user') {
  class Authenticate extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        shouldRedirect: false
      };
    }
    componentWillMount() {
      const { authState } = this.props;
      if ((authorizationLevel === 'user' && !authState.isAuthenticated)
        || (authorizationLevel === 'admin' && !authState.user.admin)) {
        this.setState({shouldRedirect: true});
      }
    }
    render() {
      if (this.state.shouldRedirect && this.props.location.pathname !== '/') {
        this.props.logout();
        return <Redirect to="/" />
      }
      return (
        <div>
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  const mapStateToProps = store => ({
    authState: store.authReducer
  });

  return withRouter(connect(mapStateToProps, {logout})(Authenticate));
}
