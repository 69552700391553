import React, { Component } from 'react';

import './PersonalDictionaryWidget.scss';

class PersonalDictionaryWidget extends Component {
  mouseDown = (ev) => {
    console.log('mouse dwon widegtpersonal')
    ev.preventDefault();
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
    this.props.dragStart(ev);
  }
  render() {
    const { newTerms, widgetMoveRef } = this.props;
    return (
      <div className="personal-dictionary-widget">
        <div className="move-dictionary" onMouseDown={this.mouseDown}>
          <i className="fa fa-arrows-alt" id="arrows-personal-dictionary" ref={widgetMoveRef}></i>
        </div>
        <div className="fa-edit-container">
          <i className="fa fa-edit fa-2x" ></i>
        </div>
        {
          newTerms > 0 ?
            <div className="new-terms">
              <span> +{ newTerms }</span>
            </div>
            :
            <div className="new-terms-hidden">
            </div>
        }
      </div>
    )
  }
}

export default PersonalDictionaryWidget;
