import { SAVE_PERSONAL_DICTIONARY, SELECT_LANGUAGE, TOOGLE_LANGUAGE_DROPDOWN, ADD_TERM, DELETE_TERM, DELETE_ALL_TERMS } from '../../constants/actionTypes';
import { DRAG_END, DRAG_START, DRAG, CLOSE } from '../../constants/dictionaryActionTypes';

export const initialState = {
  languages: ['uk', 'ru'],
  currentLanguage: 'uk',
  showLanguageDropdown: false,
  open: false,
  active: false,
  learn: false,
  terms: [],
  termsShuffled: [],
  newTerms: 0,
  lastTerm: '',
  dragItem: undefined,
  currentX: 20,
  currentY: window.innerHeight - 50,
  initialX: 0,
  initialY: 0,
  xOffset: 20,
  yOffset: window.innerHeight - 50
};

const personalDictionaryReducer = (state = initialState, action) => {
  switch(action.type) {
  case CLOSE:
    return {
      ...state,
      open: !state.open,
      newTerms: 0
    };
  case ADD_TERM:
    return {
      ...state,
      terms: [...state.terms, action.term],
      lastTerm: action.term[0],
      newTerms: state.newTerms + 1
    };
  case SAVE_PERSONAL_DICTIONARY:
    return {
      ...state,
      terms: action.personalDictionary
    };
  case DELETE_TERM:
    return {
      ...state,
      terms: state.terms.filter(term => term[0] !== action.term[0]),
      lastTerm: action.term[0],
      newTerms: state.newTerms + 1
    };
  case DELETE_ALL_TERMS:
    return {
      ...state,
      terms: []
    };
  case DRAG_END:
    return {
      ...state,
      initialX: state.currentX,
      initialY: state.currentY,
      active: false
    };
  case DRAG_START:
    return {
      ...state,
      initialX: action.clientX - state.xOffset,
      initialY: action.clientY - state.yOffset,
      active: true
    };
  case DRAG:
    return {
      ...state,
      currentX: action.clientX - state.initialX,
      currentY: action.clientY - state.initialY,
      xOffset: state.currentX,
      yOffset: state.currentY
    };
  case TOOGLE_LANGUAGE_DROPDOWN:
    return {
      ...state,
      showLanguageDropdown: !state.showLanguageDropdown
    };
  case SELECT_LANGUAGE:
    return {
      ...state,
      currentLanguage: action.language
    };
  default:
    return state;
  }
};

export default personalDictionaryReducer;
