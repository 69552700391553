import React, { Component } from 'react';
import Navigation from '../shared/components/navigation/Navigation.jsx';
import { Link } from 'react-router-dom';

import './NotFound.scss';

class NotFound extends Component {
  render() {
    return (
      <div className='wrapper'>
        <Navigation pathname={'/not-found'} select={() => this.props.history.goBack} />
        <div className="content-error">
          <h1>404</h1>
          <h2>Podana strona nie istnieje.</h2>
          <Link to="/">
            <button className="homepage-button">
              Przejdź do strony głównej
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default NotFound;
