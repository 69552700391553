import { SELECT_LANGUAGE, TOOGLE_LANGUAGE_DROPDOWN, SHOW_RESULTS, ADD_TERM, NEXT_TERM,
  DELETE_TERM, LEARN_START, LEARN_END, CHECK_ANSWER, SAVE_PERSONAL_DICTIONARY }
  from '../../constants/actionTypes';
import { CLOSE, DRAG_END, DRAG_START, DRAG,
  SET_DRAG_ITEM } from '../../constants/dictionaryActionTypes';
import { logout, saveUser } from '../../shared/actions/authActions';
import axiosDyktando from '../../utils/axiosDyktando';

export const close = () => ({
  type: CLOSE
});

export const back = () => ({
  type: LEARN_END
});

export const setDragItem = (item) => ({
  type: SET_DRAG_ITEM,
  item
});

export const dragStart = (ev) => {
  return {
    type: DRAG_START,
    clientX: ev.clientX,
    clientY: ev.clientY
  };
};

export const dragEndPersonalDictionary = () => ({
  type: DRAG_END
});

export const dragPersonalDictionary = (ev) => ({
  type: DRAG,
  clientX: ev.clientX,
  clientY: ev.clientY
});

export const getPersonalDictionary = personalDictionary => ({
  type: SAVE_PERSONAL_DICTIONARY,
  personalDictionary
});

export function handleAddTerm(term, demo = false) {
  return dispatch => {
    if(demo) {
      return dispatch(addTermToPersonalDictionary(term));
    }
    return axiosDyktando.post('/api/user/dictionary', {term})
      .then((res) => {
        dispatch(saveUser(res.data));
        dispatch(addTermToPersonalDictionary(term));
      })
      .catch(() => {
        dispatch(logout());
      });
  }
}

export function handleRemoveTerm(term, demo = false) {
  return dispatch => {
    if(demo) {
      return dispatch(deleteFromDictionary(term));
    }
    return axiosDyktando.delete('/api/user/dictionary/' + term[0])
      .then(res => {
        dispatch(saveUser(res.data));
        dispatch(deleteFromDictionary(term));
      })
      .catch(() => {
        dispatch(logout());
      });
  };
}

export const addTermToPersonalDictionary = (term) => ({
  type: ADD_TERM,
  term
});

export const deleteFromDictionary = (term) => ({
  type: DELETE_TERM,
  term
});

export const learnStart = (termsShuffled, answersShuffled) => ({
  type: LEARN_START,
  termsShuffled,
  answersShuffled
});

export const learnEnd = () => ({
  type: LEARN_END
});

export const checkAnswer = (answer, correctAnswer) => ({
  type: CHECK_ANSWER,
  answer,
  correctAnswer
});

export const nextTerm = (answersShuffled) => ({
  type: NEXT_TERM,
  answersShuffled
});

export const showResults = () => ({
  type: SHOW_RESULTS
});

export const toogleLanguageDropdown = () => ({
  type: TOOGLE_LANGUAGE_DROPDOWN
});

export const selectLanguage = (language) => ({
  type: SELECT_LANGUAGE,
  language
});
