import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import moment from 'moment';

import './CoursePage.scss';

import CourseNavigation from './components/courseNavigation/CourseNavigation.jsx';
import Shop from './components/shop/Shop.jsx';
import Learn from './components/learn/Learn.jsx';
import Account from './components/account/Account.jsx';
import Admin from './components/admin/Admin.jsx';

import { dragPersonalDictionary, dragEndPersonalDictionary, addTermToPersonalDictionary,
  toogleLanguageDropdown, selectLanguage } from './actions/personalDictionaryActions';
import { setCourses, setCurrentCourse } from './actions/courseActions';
import { logout } from '../shared/actions/authActions';
import requireAuth from '../utils/requireAuth.jsx';

export class CoursePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    try {
      const refreshTokenExpirationDate = window.localStorage.getItem('refreshTokenExpirationDate');
      let isExpired = true;
      if(refreshTokenExpirationDate !== 'undefined' && this.props.location.pathname !== '/demo') {
        const localExpirationDate = moment.utc(refreshTokenExpirationDate);
        const currentDate = moment();
        isExpired = localExpirationDate.diff(currentDate) < 0;
        console.log('session end in: ' + Math.round(localExpirationDate.diff(currentDate) / 1000) + 'seconds');
        console.log('session expired: ' + isExpired);
        if(isExpired) {
          this.logout(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.authState.refreshToken !== prevProps.authState.refreshToken) {
      if(!this.props.authState.refreshToken) {
        this.logout(true);
      }
    }
  }
  logout = (autologout) => {
    this.props.logout();
    autologout ?
      this.props.history.push('/login')
      :
      this.props.history.push('/');
  }
  drag = (ev) => {
    ev.preventDefault();
    if(this.props.personalDictionaryState.active) {
      this.props.dragPersonalDictionary(ev);
    }
  }
  dragEnd = () => {
    if(this.props.personalDictionaryState.active) {
      this.props.dragEndPersonalDictionary();
    }
  }
  render() {
    return (
      <div className="course-page" onMouseMove={this.drag} onMouseUp={this.dragEnd}>
        <CourseNavigation
          isAdmin={this.props.authState.user.admin}
          pathname={this.props.location.pathname}
          goHome={() => this.props.history.push('/')}
          logout={this.logout} />
        <div className="course-page-absolute-wrapper">
          <Route render={({ location }) => (
            <ReactCSSTransitionGroup transitionName="routes" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
              <Switch key={location.key} location={location}>
                <Route exact path='/demo' component={ Learn } />
                <Route exact path='/course' component={ Learn } />
                <Route path='/course/shop' component={ Shop } />
                <Route path='/course/account' component={ Account } />
                <Route path='/course/admin' component={ requireAuth(Admin, 'admin') } />
              </Switch>
            </ReactCSSTransitionGroup>
          )} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  personalDictionaryState: store.personalDictionaryReducer,
  courseState: store.courseReducer,
  authState: store.authReducer
});

export default withRouter(connect(mapStateToProps, {logout, setCurrentCourse, setCourses,
  selectLanguage, toogleLanguageDropdown, dragPersonalDictionary, dragEndPersonalDictionary,
  addTermToPersonalDictionary})(CoursePage));
