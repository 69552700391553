import React from 'react';
import classNames from 'classnames';

import './Course.scss';

const Course = ({level: {title, steps, icons}}) => {
  return (
    <section aria-label="Course">
      <div className="course-content" style={title !== 'TERMINOLOGIA ZAAWANSOWANA' ? {'backgroundColor': '#f6f6f6'} : {}}>
        <div className="course-title">
          <h2>{ title }</h2>
        </div>
        <div className="path" style={{height: ((steps.length - 1) * 195)}}>
          {
            steps && steps.map((step, idx) => (
              <div className="step" key={`step-${idx}`}>
                <i className={classNames('icon', 'fa', 'fa-3x', icons[idx])}></i>
                <div className="step-pointer" style={title !== 'TERMINOLOGIA ZAAWANSOWANA' ? {'backgroundColor': '#f6f6f6'} : {}}></div>
                <div key={idx} className="step-description-container">
                  <div className="step-description">
                    { step }
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  );
};

export default Course;
