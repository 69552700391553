import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './Shop.scss';
import axiosDyktando from '../../../utils/axiosDyktando.js';
import { saveUser, logout } from '../../../shared/actions/authActions.js';
import ShopOffer from './shopOffer/ShopOffer.jsx';
import PurchaseConfirmation from './purchaseConfirmation/PurchaseConfirmation.jsx';

class Shop extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      uahMidRate: undefined,
      accept: false,
      traditionalTransfer: false,
      availability: undefined,
      value: '60',
      shouldShowCoupon: false,
      coupon: {
        name: '',
        promotion: 0,
        error: ''
      },
      payPalPending: false,
      payPalOrder: false,
      purchaseConfirmation: false,
      purchaseError: false,
      duration: {
        30: 89.00,
        60: 129.00
      }
    };
  }
  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('keypress', this.onKeyPress);
    const elementExists = !!document.getElementById('paypal-button-container');
    elementExists && window.paypal.Buttons({
      style: {
        layout: 'horizontal'
      },
      createOrder: (data, actions) => {
        // Set up the transaction
        const { value, duration, coupon, payPalOrder } = this.state;
        if(payPalOrder) {
          return;
        }
        this.setState({
          payPalOrder: true
        });
        const amount =  Math.round(duration[value] * (1 - (coupon.promotion / 100)));
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: amount,
              currency_code: 'PLN',
              breakdown: {
                item_total: {
                  currency_code: 'PLN',
                  value: amount * 1
                }
              }
            },
            items: [{
              name: `Basic - ${value} - ${coupon.name}`,
              unit_amount: {
                currency_code: 'PLN',
                value: amount
              },
              quantity: 1
            }]
          }]
        });
      },
      onApprove: (data, actions) => {
      // Capture the funds from the transaction
        return actions.order.capture().then(details => {
          // Show a success message to your buyer
          // console.log('Transaction completed by: ' + details.payer.name.given_name);
          // console.log(data);
          this.setState({
            payPalPending: true
          });
          return axiosDyktando.post('/api/course/paypal-transaction-complete', {
            orderID: data.orderID
          }).then(res => {
            this.props.saveUser(res.data.user);
            this.setState({
              payPalPending: false,
              payPalOrder: false
            });
          }).catch(err => {
            // console.log(err);
            this.setState({
              purchaseError: true,
              payPalPending: false,
              payPalOrder: false
            });
          });
        });
      },
      onCancel: data => {
        window.location.reload(); 
      },
      onError: err => {
        // console.log(err.message);
        // if(err.message === 'Order could not be captured') {
        this.setState({
          purchaseError: true,
          payPalPending: false,
          payPalOrder: false
        });
        // }
      }
    }).render('#paypal-button-container');
    axios.get('/api/exchangerates/uah')
      .then(res => {
        console.log(res.data)
        this.setState({
          uahMidRate: res.data.rate
        });
      }).catch(err => {
        console.log(err);
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('onkeypress', this.onKeyPress, false);
  }
  onKeyPress = e => {
    const key = e.which || e.keyCode;
    if (key === 13) {
      this.addCoupon();
    }
  }
  handleChange = event => {
    this.setState({value: event.target.value});
  }
  handleCheckboxChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  handleTextInputChange = event => {
    const coupon = Object.assign({}, this.state.coupon);
    coupon.name = event.target.value;
    coupon.error = '';
    this.setState({ coupon });
  }
  addCoupon = () => {
    const { coupon } = this.state;
    if(!coupon.name)
      return;
    return axiosDyktando.get('/api/course/coupons/' + coupon.name)
      .then(res => {
        const { coupon } = res.data;
        const couponState = this.state.coupon;
        if(coupon) {
          couponState.promotion = coupon.promotion;
          this.setState({
            coupon: couponState
          });
        } else {
          couponState.error = 'Wprowadzony kod kuponu jest nieprawidłowy';
          this.setState({
            coupon: couponState
          });
        }
      })
      .catch(err => {
        let couponState = Object.assign({}, this.state.coupon);
        if(err.response.data.type === 'coupon-expired') {
          couponState.error = err.response.data.message;
          this.setState({
            coupon: couponState
          });
        } else if(err.response.data.type === 'coupon-not-exist') {
          couponState.error = err.response.data.message;
          this.setState({
            coupon: couponState
          });
        } else {
          couponState.error = 'Przepraszamy, coś poszło nie tak';
          this.setState({
            coupon: couponState
          });
        }
      });
  }
  toogleTraditionalTransfer = () => {
    if(!this.state.payPalOrder) {
      this.setState(prevState => ({
        traditionalTransfer: !prevState.traditionalTransfer
      }));
    }
  }
  cancel = () => {
    // this.toogleTraditionalTransfer();
    window.location.reload();
  }
  accept = () => {
    const price = this.state.duration[this.state.value];
    axiosDyktando.post('/api/course/registration', {
      duration: this.state.value,
      coupon: this.state.coupon,
      courseLevel: 'basic',
      price
    }).then(res => {
      console.log('data', res.data.user);
      this.setState({
        accept: true
      });
      this.props.saveUser(res.data.user);
      alert('Dziękujemy za zakup kursu, sprawdź swoją skrzynkę pocztową');
    }).catch(err => {
      console.log(err);
      if(err === 'refresh token expired') {
        this.props.logout();
        window.location.redirect('/');
      }
    });
  }
  render() {
    const { payPalOrder, payPalPending, traditionalTransfer, availability, value, duration, coupon, purchaseError, shouldShowCoupon } = this.state;
    const { user } = this.props.authState;
    let expirationDate = moment(user.course.basic.expiration).utc();
    const currentDate = moment().utc();
    const isExpired = expirationDate.diff(currentDate) < 0;
    console.log('isExpired', isExpired);
    expirationDate = moment(user.course.basic.expiration).format('DD.MM.YYYY, HH:mm:ss');
    return (
      <div className="shop-container">
        {
          purchaseError ?
            <div className="purchase-error">
              <i className="fa fa-3x fa-exclamation-triangle"></i>
              <p>Niestety, coś poszło nie tak. Spróbuj ponownie później lub skontaktuj się z nami przez dyktandolekarza@gmail.com</p>
              <Link to="/course/shop">
                <button className="shop-button">
                  Spróbuj ponownie
                </button>
              </Link>
            </div>
            :
            payPalPending ?
              <div className="loader" style={{flexDirection: 'column'}}>
                <i className="fa fa-spinner fa-pulse fa-2x fa-fw" style={{position: 'relative', top: -50}}></i>
                <span>Trwa przetwarzanie danych, prosimy czekać</span>
              </div>
              :
              !isExpired ?
                <div className="purchase-confirmation">
                  <i className="fa fa-3x fa-check-circle"></i>
                  <p>Dziękujemy za zaufanie!</p>
                  <p>Twoje konto jest aktywne do { expirationDate }</p>
                  <Link to="/course">
                    <button className="course-button">
                      Przejdź do kursu
                    </button>
                  </Link>
                </div>
                :
                <ShopOffer
                  title='Kurs Podstawy Terminologii'
                  availability={availability}
                  coupon={coupon}
                  value={value}
                  duration={duration}
                  shouldShowCoupon={shouldShowCoupon}
                  showCoupon={() => this.setState({shouldShowCoupon: true})}
                  addCoupon={this.addCoupon}
                  handleChange={this.handleChange}
                  handleTextInputChange={this.handleTextInputChange}
                  handleTraditionalTransfer={this.toogleTraditionalTransfer}
                  payPalOrder={payPalOrder}
                />
        }
      </div>
    );
  }
}

const mapStateToProps = store => ({
  authState: store.authReducer
});

export default connect(mapStateToProps, { saveUser, logout })(Shop);

