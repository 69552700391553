import axiosDyktando from '../../utils/axiosDyktando';

import { DELETE_ALL_TERMS } from '../../constants/actionTypes';
import { SAVE_USER, SAVE_ERRORS } from '../../constants/authActionTypes';

export const saveUser = user => ({
  type: SAVE_USER,
  user
});

export const saveErrors = errors => ({
  type: SAVE_ERRORS,
  errors
});

export const deleteTerms = () => ({
  type: DELETE_ALL_TERMS
});

export function signup(data) {
  return dispatch => {
    return axiosDyktando.post('/api/auth/signup', data)
      .then(res => {
        //console.log('1', res);
        try {
          //console.log('2', res.headers);
          //console.log('3', res.data);
          const accessToken = res.headers.authorization.split(' ')[1];
          const refreshToken = res.data.refreshToken;
          sessionStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
        } catch(err) {
          // console.log('?', err);
        }
        dispatch(saveUser(res.data.user));
        return res;
      })
      .catch(err => {
        // console.log('catch', err.response);
        throw err.response;
      });
  };
}

const saveTokens = (res) => {
  try {
    const accessToken = res.headers.authorization.split(' ')[1];
    sessionStorage.setItem('accessToken', accessToken);

    const { refreshToken, refreshTokenExpirationDate } = res.data;
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('refreshTokenExpirationDate', refreshTokenExpirationDate);
  } catch(err) {
    //ignore
    // console.log(err);
  }
};

export function login(data) {
  return dispatch => {
    // console.log(data);
    return axiosDyktando.post('/api/auth/login', data)
      .then(res => {
        // console.log(res);
	console.log('1', res);
        try {
         console.log('2', res.headers);
         console.log('3', res.data);

   	 const accessToken = res.headers.authorization.split(' ')[1];
   	 sessionStorage.setItem('accessToken', accessToken);

    	const { refreshToken, refreshTokenExpirationDate } = res.data;
    	localStorage.setItem('refreshToken', refreshToken);
    	localStorage.setItem('refreshTokenExpirationDate', refreshTokenExpirationDate);
  	} catch(err) {
    	 //ignore
    	 console.log('login save tokens err', err);
 	}
        dispatch(saveUser(res.data.user));
        return res;
      })
      .catch(err => {
        // console.log('catch', err.response);
        throw err.response;
      });
  };
}

export function logout() {
  // console.log('logout!!!!');
  return dispatch => {
    // console.log('logout inside');
    dispatch(saveUser({}));
    dispatch(deleteTerms());
    sessionStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('state');
    // return axiosDyktando.delete('/socialAuth/logout');
    return true;
  };
}

export function isUserExists(identifier) {
  return dispatch => {
    return axiosDyktando.get(`/users/search/${identifier}`);
  }
}
