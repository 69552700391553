import React from 'react';

import './Term.scss';

const Term = (props) => {
  return (
    <div className="term" onClick={props.deleteFromDictionary}>
      <li>
        <b>{props.term[0]}</b> - {props.term[props.languageIndex]}
      </li>
      <i className="fa fa-trash"></i>
    </div>
  )
}

export default Term;
