import React, { Component } from 'react';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './CourseMenu.scss';

class CourseMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: {
        elements: ['Układ narządów ruchu', 'Układ trawienny', 'Układ oddechowy', 'Układ moczowo-płciowy', 'Układ krążenia', 'Krew i jej składniki', 'Układ nerwowy', 'Układ narządów zmysłów', 'Gruczoły dokrewne', 'Chirurgia i gabinet zabiegowy', 'Choroby zakaźne', 'Położnictwo i ginekologia', 'Psychiatria', 'Powłoka wspólna i onkologia'],
        extended: false
      },
      advanced: {
        elements: [],
        extended: false
      }
    };
  }
  extend = (level) => {
    this.setState(prevState => ({
      [level]: {
        ...prevState[level],
        extended: !prevState[level].extended
      }
    }));
  }
  render() {
    return (
      <div className="course-menu">
        <div className="course course-basic">
          <div className="course-title" onClick={() => this.extend('basic')}>
            <p>PODSTAWY TERMINOLOGII</p>
            <i className={classNames(['fa', {'fa-caret-down': !this.state.basic.extended, 'fa-caret-up': this.state.basic.extended}])}></i>
          </div>
          <TransitionGroup className='course-elements-container'>
            <CSSTransition
              key={this.state.basic.extended}
              timeout={400}
              classNames='slide-top'>
              <div className='absolute-wrapper'>
                {
                  this.state.basic.extended && this.props.demo &&
                  <div className='lock-container'>
                    <i className='fa fa-2x fa-lock'></i>
                  </div>
                }
                <ul className="course-elements">
                  {
                    this.state.basic.extended &&
                    this.state.basic.elements.map((element, idx) => (
                      <li key={idx}
                        className={classNames('course-element', {'course-selected': this.props.currentCourse - 1 === idx})}
                        onClick={() => this.props.openCourse(idx)}>
                        { element }
                      </li>
                    ))
                  }
                </ul>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    )
  }
}

export default CourseMenu;

// <div className='course course-advanced'>
  // <div className='course-title' onClick={() => this.extend('advanced')}>
  //   <p>TERMINOLOGIA ZAAWANSOWANA</p>
  //   <i className={classNames(['fa', 'fa-lock'])}></i>
  // </div>
  // <TransitionGroup className='course-elements-container'>
  //   <CSSTransition
  //     key={this.state.advanced.extended}
  //     timeout={400}
  //     classNames='slide-top'>
  //     <div className='absolute-wrapper'>
  //       {
  //         this.state.advanced.extended && this.props.demo &&
  //         <div className='lock-container'>
  //           <i className='fa fa-2x fa-lock'></i>
  //         </div>
  //       }
  //       <ul className='course-elements'>
  //         {
  //           this.state.advanced.extended && this.state.advanced.elements.map((element, idx) => <li key={idx}> { element } </li>)
  //         }
  //       </ul>
  //     </div>
  //   </CSSTransition>
  // </TransitionGroup>
// </div>

