import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

import './About.scss';
import AboutDescription from './aboutDescription/AboutDescription.jsx';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderIdx: 1,
      lastSliderIdx: 1,
      slideLeft: true,
      slideRight: false,
      shouldShowSwipeInfo: true
    };
  }
  componentDidMount() {
    this.detectSwipe('aboutDescription', this.nextSlide);
  }
  detectSwipe = (elementId, func) => {
    let swipe_det = {};
    swipe_det.sX = 0; swipe_det.sY = 0; swipe_det.eX = 0; swipe_det.eY = 0;
    const min_x = 30;  //min x swipe for horizontal swipe
    // const max_x = 30;  //max x difference for horizontal swipe
    // const min_y = 50;  //min y swipe for vertical swipe
    // const max_y = 60;
    let direcX = 0;
    let direcY = 0;
    let element = document.getElementById(elementId);
    if(!element)
      return;
    element.addEventListener('touchstart', (e) => {
      const t = e.touches[0];
      swipe_det.sX = t.screenX;
      swipe_det.sY = t.screenY;
    }, false);
    element.addEventListener('touchmove', (e) => {
      const horizontalSwipe = ((swipe_det.eX - min_x > swipe_det.sX) ||
      (swipe_det.eX + min_x < swipe_det.sX)) && (swipe_det.eX > 0);
      if (horizontalSwipe) {
        e.preventDefault();
      }
      const t = e.touches[0];
      swipe_det.eX = t.screenX;
      swipe_det.eY = t.screenY;
    }, false);
    element.addEventListener('touchend', () => {
      //horizontal detection
      const shouldSwipeHorizontaly = ((swipe_det.eX - min_x > swipe_det.sX) ||
      (swipe_det.eX + min_x < swipe_det.sX)) && (swipe_det.eX > 0);
      if (shouldSwipeHorizontaly) {
        if(swipe_det.eX > swipe_det.sX) direcX = -1;
        else direcX = 1;
      }
      //vertical detection
      // else if ((((swipe_det.eY - min_y > swipe_det.sY) || (swipe_det.eY + min_y < swipe_det.sY))
      //   && ((swipe_det.eX < swipe_det.sX + max_x) && (swipe_det.sX > swipe_det.eX - max_x)
      //   && (swipe_det.eY > 0)))) {
      //   if(swipe_det.eY > swipe_det.sY) direcY = -1;
      //   else direcY = 1;
      // }
      if (direcX !== 0) {
        if (typeof func == 'function') func(direcX, direcY);
      }
      direcX = 0;
      // direcY = 0;
      // swipe_det.sY = 0;
      // swipe_det.eY = 0;
      swipe_det.sX = 0;
      swipe_det.eX = 0;
    }, false);
  }
  changeSlide = (slideNum, direction = null) => {
    this.setState((prevState) => ({
      slideLeft: direction === null ? slideNum > prevState.sliderIdx : direction === 1,
      slideRight: direction === null ? slideNum < prevState.sliderIdx : direction === -1,
      shouldShowSwipeInfo: false
    }), () => {
      this.setState((prevState) => ({
        sliderIdx: slideNum,
        lastSliderIdx: prevState.sliderIdx
      }));
    });
  }
  nextSlide = (directionX, directionY) => {
    // console.log('directionX', directionX)
    // console.log('directionY', directionY)
    if(directionY === 1) {
      this.props.scrollToTab('faq');
    } else if(directionY === -1) {
      this.props.scrollToTab('about');
    }
    if(this.state.sliderIdx + directionX > 4) {
      this.changeSlide(1, directionX);
    } else if(this.state.sliderIdx + directionX < 1) {
      this.changeSlide(4, directionX);
    } else {
      this.changeSlide(this.state.sliderIdx + directionX, directionX);
    }
  }
  render() {
    let description;
    if(this.state.sliderIdx === 1) {
      description = (
        <div className="description">
          <h3>O mnie</h3>
          <p>
            Mam na imię Lena, w 2011 skończyłam Państwowy Uniwersytet Medyczny w Kijowie i rozpoczęłam
            szkolenie specjalizacyjne z chorób wewnętrznych w Kijowie, po roku szkolenia zaczęłam przygodę
            z językiem polskim od zera. Uczyłam się sama, później w szkole językowej,
            ale najlepszą decyzją były korepetycje z rodowitym Polakiem. Pod koniec roku 2013 ukończyłam
            specjalizację z chorób wewnętrznych, przepracowałam około 3 miesięcy jako lekarz,
            po czym się zwolniłam ponieważ zaczynałam proces nostryfikacyjny.
          </p>
        </div>
      );
    } else if(this.state.sliderIdx === 2) {
      description = (
        <div className="description">
          <h3>Materiały pomocnicze</h3>
          <p>
            W 2013/2014 roku było bardzo mało informacji na temat nostryfikacji dyplomu medycznego,
            opinii o uczelniach, cenach, terminach, odsetkach zdawalności, właśnie wtedy założyłam grupę
            na <a href="https://www.facebook.com/groups/291270561038032/" rel="noopener noreferrer" target="_blank">Facebooku “Nostryfikacja dyplomu medycznego w Polsce”</a> celem łatwej komunikacji z innymi ludźmi.
            Wiedziałam, że raz napisana opinia zostanie na zawsze i kolejni lekarze będą mieli więcej informacji.
            Na początku sama pisałam rozległe opinie, póżniej zaczęli pisać inni lekarze. Czasy się zmieniły,
            aktualnie liczba członków grupy dobija do prawie 3 000 osób, są tam informacje i opinie o nostryfikacji
            praktycznie w każdej uczelni w Polsce, dzięki pracy członków grupy stworzyliśmy osobne konto na <a href="https://drive.google.com/drive/folders/1ah8kY_ZJVXbxN0rClCnTvcHK-YMTZVdd" rel="noopener noreferrer" target="_blank">Google Drive</a>,
            gdzie bezpłatnie dostępne są materiały do nauki języka polskiego, książki medyczne, skrypty i różne testy.
          </p>
        </div>
      );
    } else if(this.state.sliderIdx === 3) {
      description = (
        <div className="description">
          <h3>O stronie</h3>
          <p>
            Cały czas czytam i moderuję tą grupę, po 4 latach robię wniosek że posiadamy dość dużą wiedzę na temat
            nostryfikacji dyplomu medycznego, skrócenia stażu podyplomowego czy szkolenia specjalizacyjnego,
            uznania specjalizacji, tytułów naukowych. Niezmiennym pozostaje jednak konieczność nauki języka polskiego
            i medycznej terminologii. Intencją moją było stworzenie strony internetowej, która pomoże w szybkiej nauce
            terminologii medycznej i przygotuje do egzaminu ze znajomości języka polskiego. Wszystkie materiały
            zamieszczone na stronie dyktandolekarza.pl opracowałam sama na podstawie ponad 15 książek medycznych.
            Każdy tekst był napisany tak żeby mieścił w sobie jak najwięcej terminologii medycznej. Nie znajdziesz je
            na żadnym innym portalu społecznościowym, bądź innych stronach internetowych.
            Na stronie jest wersja testowa, gorąco zapraszam do zapoznania się.
          </p>
        </div>
      );
    } else if(this.state.sliderIdx === 4) {
      description = (
        <div className="description">
          <h3>Obecnie</h3>
          <p>
            Nostryfikację robiłam w Śląskim Uniwersytecie medycznym, aktualnie już od 4 lat mieszkam w Polsce,
            skończyłam staż podyplomowy, zdałam Lekarski Egzamin Państowowy, i jestem w połowie specjalizacji z
            chorób wewnętrznych w trybie rezydenckim. Wszystko się da zrobić, ale czasami wymaga to od nas dużego
            zaparcia i ciężkiej pracy. Mam nadzieję, że moje kursy ułatwią Ci naukę do ezgamniu nostryfikacyjnego,
            powodzenia!
          </p>
        </div>
      );
    }
    return (
      <section aria-label="About" id="about">
        <div className="about-content">
          <div className="about-image">
            <div className="about-avatar"/>
            <div className="about-overlay"></div>
          </div>
          <div className="about-description-container" id="aboutDescription">
            <div className="about-description">
              <div className="slide-left" onClick={() => this.nextSlide(-1)}>
                <i className="fa fa-2x fa-angle-left"></i>
              </div>
              <TransitionGroup className="card-container">
                <CSSTransition
                  key={this.state.sliderIdx}
                  timeout={500}
                  classNames={classNames(
                    {'slide-left': this.state.slideLeft, 'slide-right': this.state.slideRight}
                  )} >
                  <AboutDescription>
                    {
                      description
                    }
                  </AboutDescription>
                </CSSTransition>
              </TransitionGroup>
              {
                window.innerWidth < 1125 &&
                <div className={classNames(
                  'swipe-info',
                  {'swipe-info-hidden': !this.state.shouldShowSwipeInfo}
                )}>
                  <i className="fa fa-3x fa-arrows-alt-h"></i>
                  <i className="fa fa-3x fa-hand-point-up"></i>
                </div>
              }
              <div className="slide-right" onClick={() => this.nextSlide(1)}>
                <i className="fa fa-2x fa-angle-right"></i>
              </div>
            </div>
            <div className="slider">
              <div
                onClick={() => this.changeSlide(1)}
                style={this.state.sliderIdx === 1 ? {backgroundColor: '#04596a'} : {}}>
              </div>
              <div
                onClick={() => this.changeSlide(2)}
                style={this.state.sliderIdx === 2 ? {backgroundColor: '#04596a'} : {}}>
              </div>
              <div
                onClick={() => this.changeSlide(3)}
                style={this.state.sliderIdx === 3 ? {backgroundColor: '#04596a'} : {}}>
              </div>
              <div
                onClick={() => this.changeSlide(4)}
                style={this.state.sliderIdx === 4 ? {backgroundColor: '#04596a'} : {}}>
              </div>
            </div>
          </div>
        </div>
      </section>

    );
  }
}

export default About;
