import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import '../App.scss';
import './LandingPage.scss';
import LandingNavigation from './landingNavigation/LandingNavigation.jsx';
import Homepage from './homepage/Homepage.jsx';
import Offer from './offer/Offer.jsx';
import Course from './course/Course.jsx';
import About from './about/About.jsx';
import Faq from './faq/Faq.jsx';
import Footer from './footer/Footer.jsx';

let timeout;

export class LandingPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      basic: {
        title: 'PODSTAWY TERMINOLOGII',
        steps: [
          'Zapoznaj się z terminologią do działu',
          'Przeczytaj tekst',
          'Naucz się trudnych słówek z interaktywnym słownikiem',
          'Odpowiedz na 5 pytań do tekstu'
        ],
        icons: [
          'fa-book-open',
          'fa-book-reader',
          'fa-edit',
          'fa-check-square',
        ]
      },
      advanced: {
        title: 'TERMINOLOGIA ZAAWANSOWANA',
        steps: [
          'Zapoznaj się z terminologią do działu',
          'Posłuchaj nagranie dyktanda I raz',
          'Odpowiedz na 5 pytań dotyczących dyktanda',
          'Posłuchaj nagranie dyktanda II raz i napisz co słyszysz na kartce',
          'Sprawdź poprawność napisanego dyktanda z oryginałem'
        ],
        icons: [
          'fa-book-open',
          'fa-headphones',
          'fa-question',
          'fa-pen',
          'fa-check-square'
        ]
      },
      isNewsletterOpen: false,
      shouldShowScrollToTop: false
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', () => {
      if(window.scrollY > window.innerHeight && !this.state.shouldShowScrollToTop) {
        this.setState({shouldShowScrollToTop: true});
      } else if(window.scrollY < window.innerHeight && this.state.shouldShowScrollToTop) {
        this.setState({shouldShowScrollToTop: false});
      }
    });
    document.getElementsByTagName('html')[0].style = 'overflow-x: hidden;';
    document.getElementsByTagName('body')[0].style = 'overflow-x: hidden;';
    let alreadyPoppedUp;
    try {
      alreadyPoppedUp = localStorage.getItem('poppedUp');
    } catch(err) {
      // ignore
    }
    if(!alreadyPoppedUp) {
      timeout = setTimeout(() => {
        this.setState({
          isNewsletterOpen: true
        });
      }, 4000);
    }
  }
  toogleModal = () => {
    clearTimeout(timeout);
    this.setState(prevState => ({
      isNewsletterOpen: !prevState.isNewsletterOpen
    }));
  }
  newsletterPoppedUp = () => {
    localStorage.setItem('poppedUp', true);
  }
  scrollToTab = (tab) => {
    let windowHeight = window.innerHeight;
    const totalHeight = document.body.scrollHeight;
    const footerHeight = document.getElementById('footer').offsetHeight;
    const offerHeight = document.getElementById('offer').offsetHeight;
    // const courseHeight = document.getElementById('course').offsetHeight;
    const navHeight = document.getElementById('nav').offsetHeight;
    const faqHeight = document.getElementById('faq').offsetHeight;
    const aboutHeight = document.getElementById('about').offsetHeight;
    let scrollHeight = 0;
    switch(tab) {
    case 'home':
      break;
    case 'offer':
      scrollHeight = windowHeight - 10;
      break;
    case 'course':
      scrollHeight = windowHeight + offerHeight - (0.5 * navHeight);
      break;
    case 'about':
      scrollHeight = totalHeight - faqHeight - aboutHeight - footerHeight - (0.25 * navHeight);
      break;
    case 'faq':
      scrollHeight = totalHeight - faqHeight - footerHeight - (0.75 * navHeight);
      break;
    default:
      break;
    }
    window.scrollTo({
      top: scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }
  render() {
    const { basic, advanced, isNewsletterOpen } = this.state;
    return (
      <div>
        <LandingNavigation
          isAuthenticated={this.props.authState.isAuthenticated}
          scrollToTab={(tab) => this.scrollToTab(tab)} />
        <main>
          <Homepage
            newsletterPoppedUp={this.newsletterPoppedUp}
            toogleModal={this.toogleModal}
            isNewsletterOpen={isNewsletterOpen}
            isAuthenticated={this.props.authState.isAuthenticated}/>
          <Offer />
          <Course level={basic} />
          <Course level={advanced} />
          <About scrollToTab={(tab) => this.scrollToTab(tab)} />
          <Faq />
          <div className={classNames(
            'scroll-to-top',
            {'scroll-to-top-hidden': !this.state.shouldShowScrollToTop}
          )}
          onClick={() => this.scrollToTab('home')} >
            <i className="fa fa-chevron-up"></i>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  authState: store.authReducer
});

export default connect(mapStateToProps, {})(LandingPage);
