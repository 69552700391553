import React from 'react';

const Step1 = () => {
  return (
    <div className="self-tutorial-text">
      <p>W tym samouczku zaprezentujemy podstawowe funkcjonalności strony, aby nauka była dla Ciebie jak najbardziej przyjemna i efektywna.</p>
      <p>Samouczek możesz w każdej chwili wyłączyć klikając <i className="fa fa-times-circle"></i>, w prawym górnym rogu</p>
    </div>
  );
};

export default Step1;
