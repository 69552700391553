  import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import './Account.scss';

class Account extends Component {
  render() {
    const { user } = this.props.authState;
    const expirationDate = moment(user.course.basic.expiration);
    const currentDate = moment();
    const isExpired = expirationDate.diff(currentDate) < 0;
    return (
      <div className="account-container">
        <div className="account-info">
          <h2>
            <i className="fa fa-2x fa-user-md"></i>
            Mój profil
          </h2>
          <div>
            <p><span className="label">Adres e-mail:</span></p>
            <p> { user.email } </p>
          </div>
          <div>
            <p><span className="label">Dostępność kursów:</span></p>
            <div className="courses-availability">
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                Podstawy Terminologii
                <span>-</span>
                <span className={classNames({'expired': isExpired}, {'active': !isExpired})}>
                  {
                    isExpired ? 'Niedostępny' : moment(user.course.basic.expiration).format('DD.MM.YYYY, HH:mm:ss')
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  authState: store.authReducer
});

export default connect(mapStateToProps, {})(Account);
