import React, { Component } from 'react';

import './CourseNavigation.scss';

import Logo from 'shared/components/logo/Logo.jsx';
import CourseNavigationTabs from './courseNavigationTabs/CourseNavigationTabs.jsx';

class CourseNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldShowProfileMenu: false
    };
  }
  toogleProfileMenu = () => {
    this.setState(prevState => ({
      shouldShowProfileMenu: !prevState.shouldShowProfileMenu
    }));
  }
  render() {
    return (
      <nav className="course-navigation course-navigation-sticky">
        <Logo goHome={this.props.goHome} />
        <CourseNavigationTabs
          isAdmin={this.props.isAdmin}
          pathname={this.props.pathname}
          logout={this.props.logout}
          shouldShowProfileMenu={this.state.shouldShowProfileMenu}
          toogleProfileMenu={this.toogleProfileMenu} />
      </nav>
    );
  }
}

export default CourseNavigation;
