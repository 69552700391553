import React from 'react';
import classNames from 'classnames';

import './ProfileButton.scss';

const ProfileButton = (props) => {
  return (
    <div className="profile-button" onClick={props.toogleProfileMenu}>
      <div className={classNames('profile-icon', {'profile-icon-sticky': props.isSticky})}>
        <i className="fa fa-2x fa-user-md"></i>
      </div>
      <i style={props.shouldHideArrow ? {display: 'none'} : {}} className={classNames('fa', {'fa-angle-down': !props.shouldShowProfileMenu}, {'fa-angle-up': props.shouldShowProfileMenu})}></i>
    </div>
  )
}

export default ProfileButton;

