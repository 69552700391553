import React from 'react';
import classNames from 'classnames';

import './Navigation.scss';
import Logo from '../logo/Logo.jsx';

const Navigation = (props) => {
  return (
    <nav className="navigation navigation-sticky">
      <Logo goHome={() => props.history.push('/')} />
      <div className="navigation-tabs">
        <div className="navigation-tab navigation-tab-goback"
          onClick={() => props.history.goBack()} >
          <p>POWRÓT</p>
          <i className={classNames('fa', 'fa-arrow-circle-left')}>
          </i>
        </div>
      </div>
    </nav>
  )
}

export default Navigation;
