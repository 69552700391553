import React from 'react';
import classNames from 'classnames';

import './LanguageDropdown.scss';

const LanguageDropdown = props => {
  return (
    <div
      className={classNames(
        'dropdown-container',
        {'dropdown-mobile': props.mobile},
        {'dropdown-container-open': props.showLanguageDropdown}
      )}
      onClick={props.toogleLanguageDropdown}>
      <div className="dropdown">
        <img
          src={require(`assets/flag-${props.currentLanguage}.png`)}
          alt={`${props.currentLanguage} flag`}
          className="flag" />
        <i className="fa fa-caret-down"></i>
      </div>
      <div
        className="dropdown-options"
        style={props.showLanguageDropdown ? {opacity: 1} : {opacity: 0}}>
        <div
          className="language-dropdown"
          onClick={() => props.selectLanguage(props.languageToSelect[0])} >
          <img
            src={require(`assets/flag-${props.languageToSelect}.png`)}
            alt={`${props.languageToSelect} flag`}
            className="flag" />
        </div>
      </div>
    </div>
  )
}

export default LanguageDropdown;
