import React from 'react';

import './PersonalDictionaryFull.scss';

const PersonalDictionaryFull = (props) => {
  const { currentLanguage, questionsLanguage, answersLanguage } = props;
  return (
    <div className='personal-dictionary-full'>
      <div className="terms-container" style={
        props.termComponents.length === 0 ? {padding: 0} : {}
      }>
        <div className="terms-title">
          <div className="title">SŁOWNIK INTERAKTYWNY</div>
          <i className="fa fa-times-circle" title="zminimalizuj" onClick={props.close}></i>
        </div>
        <ul className="terms">
          {
            props.termComponents.length > 0 ?
              props.termComponents
              :
              <div className="terms-empty">
                <p>Kliknij na słowo aby dodać</p>
              </div>
          }
        </ul>
      </div>
      <form>
        <div className="horizontal-line"></div>
        <button type="submit" onClick={props.learnStart}>Rozpocznij naukę</button>
        <div className="questions-flag" onClick={props.replaceLanguages}>
          <img src={require(`assets/flag-${questionsLanguage}.png`)}
            alt={`${questionsLanguage || currentLanguage} flag`} className="flag" />
          <i className="fas fa-arrows-alt-h"></i>
          <img src={require(`assets/flag-${answersLanguage}.png`)}
            alt={`${answersLanguage} flag`} className="flag" />
        </div>
      </form>
    </div>
  );
};

export default PersonalDictionaryFull;
