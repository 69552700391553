import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep, clone } from 'lodash';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import './Learn.scss';

import LearnNotAvailable from '../learnNotAvailable/LearnNotAvailable.jsx';
import MessageBox from './messageBox/MessageBox.jsx';
import TerminologyBasic from './terminologyBasic/TerminologyBasic.jsx';
import CourseMenu from './courseMenu/CourseMenu.jsx';
import Dictionary from './dictionary/Dictionary.jsx';
import PersonalDictionary from './personalDictionary/PersonalDictionary.jsx';
import SelfTutorial from './selfTutorial/SelfTutorial.jsx';
import LanguageDropdown from './languageDropdown/LanguageDropdown.jsx';
import Error from '../error/Error.jsx';

import { dragPersonalDictionary, dragEndPersonalDictionary, handleAddTerm,
  toogleLanguageDropdown, selectLanguage, getPersonalDictionary }
  from '../../actions/personalDictionaryActions';
import { setCourses, setCurrentCourse } from '../../actions/courseActions';
import { logout } from 'shared/actions/authActions';

import axiosDyktando from 'utils/axiosDyktando';

let timeout;

class Learn extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      personalDictionaryInitialization: false,
      shouldRedirect: false,
      title: '',
      text: '',
      subtext: '',
      dictionary: [],
      questions: [],
      message: {
        type: '',
        value: ''
      },
      pending: false,
      termHovered: ['zęby'],
      demo: false,
      mobile: false,
      laptopBreakpoint: 1125 // make sure that laptop breakpoint in mixins is equal
    };
    this.handleResizing = this.handleResizing.bind(this); //bind function once
  }
  componentDidMount() {
    this._isMounted = true;
    const { location, authState, personalDictionaryState } = this.props;
    if(window.innerWidth <= this.state.laptopBreakpoint) {
      this.setMobile(true);
    } else {
      this.setMobile(false);
    }
    window.addEventListener('resize', this.handleResizing);
    document.getElementsByTagName('html')[0].style = 'overflow: hidden;';
    document.getElementsByTagName('body')[0].style = 'overflow: hidden;';
    if(location.pathname === '/demo') {
      this.setState({demo: true});
      this.openDemoCourse();
    } else {
      this.setState({demo: false});
      if(this.isCourseExpired()) {
        this.setState({shouldRedirect: true});
      }
      const shouldLoadPersonalDictionary =
        !isEqual(authState.user.personalDictionary, personalDictionaryState.terms);
      if(shouldLoadPersonalDictionary) {
        this.setState({
          personalDictionaryInitialization: true
        }, () => {
          this.props.getPersonalDictionary(this.props.authState.user.personalDictionary);
        });
      }
      if(this.props.courseState.currentCourse === null) {
        this.openCourse(0);
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(timeout);
    window.removeEventListener('resize', this.handleResizing, false);
  }
  handleResizing = () => {
    if(window.innerWidth <= this.state.laptopBreakpoint) {
      this.setMobile(true);
    } else {
      this.setMobile(false);
    }
  }
  setMobile = mobile => {
    this.setState({
      mobile
    });
  }
  componentWillReceiveProps(nextProps) {
    const terms = this.props.personalDictionaryState.terms;
    const nextTerms = nextProps.personalDictionaryState.terms;
    const lastTerm = nextProps.personalDictionaryState.lastTerm;
    if(this.props.authState.isAuthenticated !== nextProps.authState.isAuthenticated) {
      !nextProps.authState.isAuthenticated && this.props.history.push('/login');
    }
    if(terms !== nextTerms) {
      if(terms.length === nextTerms.length || lastTerm === '' || this.state.personalDictionaryInitialization) {
        this.setState({
          personalDictionaryInitialization: false
        });
        return;
      }
      clearTimeout(timeout);
      if(terms.length < nextTerms.length) {
        this.setState({
          message: {
            type: 'add',
            value: `Dodano nowe słowo "${lastTerm}" do interaktywnego słownika`
          }
        });
      } else {
        this.setState({
          message: {
            type: 'remove',
            value: `Usunięto słowo "${lastTerm}" z interaktywnego słownika`,
          }
        });
      }
      this.hideMessage().then((msg) => {
        // console.log(msg);
      });
    }
  }
  hideMessage = () => {
    return new Promise((resolve) => {
      timeout = setTimeout(() => {
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            value: ''
          }
        }), () => {
          resolve('ok');
        });
      }, 4000);
    });
  }
  hideMessageNow = () => {
    this.setState((prevState) => ({
      message: {
        ...prevState.message,
        value: ''
      }
    }));
  }
  isCourseExpired = () => {
    const expirationDate = moment(this.props.authState.user.course.basic.expiration);
    const currentDate = moment();
    const isExpired = expirationDate.diff(currentDate) < 0;
    return isExpired;
  }
  clickTerm = (termToAdd) => {
    const termAlreadyInDictionary =
      this.props.personalDictionaryState.terms.find(term => term[0] === termToAdd[0]);
    if(!termAlreadyInDictionary) {
      this.props.handleAddTerm(termToAdd, this.state.demo);
    } else {
      clearTimeout(timeout);
      this.setState({
        message: {
          type: 'warning',
          value: `Słowo "${termToAdd[0]}" już dodano do interaktywnego słownika`
        }
      });
      this.hideMessage().then((msg) => {
        // console.log(msg);
      });
    }
  }
  openDemoCourse = () => {
    this.setState({pending: true});
    axiosDyktando.get('/api/course/0').then(({
      data: {title, text, subtext, dictionary, questions}
    }) => {
      this.setState({pending: false});
      let courses = clone(this.props.courseState.courses);
      courses[0] = {title, text, subtext, dictionary, questions};
      this.props.setCourses(courses); // this.props.setCurrentCourse(0);
    }).catch(() => {
      this.setState({pending: false});
    });
  }
  openCourse = (element) => {
    const { courses } = this.props.courseState;
    if(this.props.location.pathname !== '/demo') {
      if(!courses[element + 1]) {
        this.setState({pending: true});
        axiosDyktando.get(`/api/course/${element + 1}`).then(({
          data: {title, text, subtext, dictionary, questions}
        }) => {
          if (this._isMounted) {
            this.setState({pending: false});
            courses[element + 1] = {title, text, subtext, dictionary, questions};
            this.props.setCourses(courses);
            this.props.setCurrentCourse(element + 1);
          }
        }).catch(err => {
          if (this._isMounted) {
            this.setState({pending: false});
          }
          // console.log(err.response);
          if(err === 'refresh token expired') {
            this.props.logout();
            window.location.reload();
          }
        });
      } else {
        this.props.setCurrentCourse(element + 1);
      }
    }
  }
  showWarningMessage = () => {
    clearTimeout(timeout);
    this.setState({
      message: {
        type: 'warning',
        value: `Aby rozpocząć naukę musisz dodać conajmniej 4 słowa do interaktywnego
                słownika. Kliknij na podkreślone słowo, aby dodać je do słownika`
      }
    });
    this.hideMessage().then((msg) => {
      // console.log(msg);
    });
  }
  render() {
    const { message, pending, demo, shouldRedirect, mobile, termHovered } = this.state;
    const { languages, currentLanguage, showLanguageDropdown } = this.props.personalDictionaryState;
    const { courses, currentCourse } = this.props.courseState;
    const { toogleLanguageDropdown, selectLanguage } = this.props;
    if(shouldRedirect) {
      return <LearnNotAvailable />;
    }
    const languagesClone = cloneDeep(languages);
    const languageToSelect = languagesClone.filter(language => language !== currentLanguage);
    const languageIndex = languagesClone.findIndex(language => language === currentLanguage) + 1;
    let chapter;
    if(demo) {
      chapter = cloneDeep(courses[0]);
    } else if(currentCourse === null) {
      chapter = cloneDeep(courses[1]);
    } else {
      chapter = cloneDeep(courses[currentCourse]);
    }
    return (
      <div className="learn-wrapper" >
        <MessageBox message={message} hideMessageNow={this.hideMessageNow} />
        {
          !demo ?
            <CourseMenu
              openCourse={(element) => this.openCourse(element)}
              currentCourse={currentCourse}
              demo={demo} />
            :
            <SelfTutorial />
        }
        {
          chapter ?
            <TerminologyBasic
              clickTerm={(term) => this.clickTerm(term)}
              chapter={chapter}
              termHovered={termHovered}
              languages={languages}
              currentLanguage={currentLanguage}
              demo={demo} />
            :
            pending ?
              <div className="loader">
                <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
              </div>
              :
              <Error />
        }
        {
          chapter && chapter.dictionary &&
        <Dictionary
          dictionary={chapter.dictionary}
          languages={languages}
          currentLanguage={currentLanguage}
          showLanguageDropdown={showLanguageDropdown}
          selectLanguage={selectLanguage}
          toogleLanguageDropdown={toogleLanguageDropdown}
          clickTerm={(term) => this.clickTerm(term)}
          hoverTerm={(term) => this.setState({termHovered: term})} />
        }
        {
          mobile &&
          <LanguageDropdown
            mobile={true}
            showLanguageDropdown={showLanguageDropdown}
            languageToSelect={languageToSelect}
            currentLanguage={currentLanguage}
            toogleLanguageDropdown={toogleLanguageDropdown}
            selectLanguage={(language) => this.props.selectLanguage(language)} />
        }
        <PersonalDictionary
          demo={demo}
          showWarningMessage={this.showWarningMessage}
          languages={languages}
          currentLanguage={currentLanguage}
          languageIndex={languageIndex} />
      </div>
    )
  }
}

const mapStateToProps = store => ({
  personalDictionaryState: store.personalDictionaryReducer,
  selfTutorialState: store.selfTutorialReducer,
  courseState: store.courseReducer,
  authState: store.authReducer
});

export default withRouter(connect(mapStateToProps, {logout, setCurrentCourse, setCourses,
  selectLanguage ,toogleLanguageDropdown, dragPersonalDictionary, dragEndPersonalDictionary,
  handleAddTerm, getPersonalDictionary})(Learn));
