import React from 'react';

const Step4 = () => {
  return (
    <div className="self-tutorial-text">
      <p>
        Kliknij na
        <div className="fa-edit-container">
          <i className="fa fa-edit fa-2x" ></i>
        </div>
        aby otworzyć interaktywny słownik. Naucz się słówek które dodałeś/łaś do słownika a następnie kliknij
        przycisk "Rozpocznik naukę", aby sprawdzić swoją znajomość słówek. Nad przyciskiem znajduje się strzałka
        <i className="fas fa-arrows-alt-h"></i>, za jej pomocą możesz zamienić język pytań i odpowiedzi
      </p>
    </div>
  )
}

export default Step4;
