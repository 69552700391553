import React from 'react';
import { Link } from 'react-router-dom';

import './LearnNotAvailable.scss';

const LearnNotAvailable = () => {
  return (
    <div className="learn-not-available-wrapper" >
      <div className="learn-not-available">
        <div className="learn-not-available-content">
          <i className="fa fa-3x fa-lock"></i>
          <h2>Wyświetlenie zawartości bez wykupionego dostępu jest niemożliwe</h2>
          <div className="buy">
            <Link to="/course/shop">
              Wykup dostęp
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LearnNotAvailable;
