import React from 'react';
import processString from 'react-process-string';
import { cloneDeep } from 'lodash';

import './TerminologyBasic.scss';

// eslint-disable-next-line
const dictionaryReport = ({dictionary, title}, total, term) => {
  const index = dictionary.findIndex(termToFind => termToFind[0] === term[0]);
  dictionary.splice(index, 1);
  const termsNotCaught = dictionary.map(terms => terms[0]);
  const notcaught = dictionary.length;
  console.log(`
    ${title.toUpperCase()}
    total ${total}
    not caught ${notcaught}
    not caught percentage ${Math.round((notcaught * 100 / total))}%
    caught ${total - notcaught}
    caught percentage ${Math.round(((total - notcaught) * 100 / total))}%
    terms not caught:
    ${JSON.stringify(termsNotCaught)}
  `);
};

const processText = (props, languageIndex) => {
  const chapter = cloneDeep(props.chapter);
  // eslint-disable-next-line
  const totalDictionaryLength = chapter.dictionary.length;
  let reg = '(\\b|^|\\s|[(])(';  // regex for finding dictionary terms in text
  reg += chapter.dictionary.map(term => {
    let idx = 0;
    if(term[3]) { // if alias exists use it
      idx = 3;
    }
    term[idx] = term[idx].trim(); // in case there are additional space
    const words = term[idx].split(' ');
    if(words.length > 1) {
      return words.map(word => word.toLowerCase().slice(0,-1) + '.{1,2}?').join(' ');
    } else {
      return term[idx].toLowerCase().slice(0,-1) + '.{1,2}?';
    }
  }).join('|');
  reg += ')';
  reg += '(\\b|$|\\s|[)]|[).])';
  // console.log(reg);
  const regex = new RegExp(reg, 'ig');
  let config = [{
    regex,
    fn: (key, match) => {
      let reg2; // regex for finding specific term matching word from text
      const words = match[2].toLowerCase().split(' ');
      if(words.length > 1) {
        reg2 = '^';
        reg2 += words.map(word => word.toLowerCase().slice(0,-1) + '.{0,2}?').join(' ');
        reg2 += '$';
      } else {
        reg2 = '^' + match[2].toLowerCase().slice(0,-1) + '.{0,2}?$';
      }
      const regex = new RegExp(reg2, 'ig');
      const term = chapter.dictionary.find(term => {
        let idx = 0;
        if(term[3]) { // if alias exists use it
          idx = 3;
        }
        return term[idx].toLowerCase().match(regex);
      });
      // if(process.env.NODE_ENV === 'development' && term && term[0]) {
      //   dictionaryReport(chapter, totalDictionaryLength, term);
      // }
      return (
        <div className="chapter-term" key={'term-' + key}>
          <span className='term-gap' key={'term-gap-' + key}>
            { match[1] }
          </span>
          {
            term ?
              <span className="term-hoverable" id={term[0]}
                onClick={() => window.innerWidth > 1125 && props.clickTerm(term)}
                key={'term-hoverable-' + key}>
                { match[2] }
                <div className="term-dictionary-container"
                  key={'term-dictionary-container-' + key}>
                  <div className="term-dictionary"
                    onClick={() => window.innerWidth < 1125 && props.clickTerm(term)}>
                    {
                      term && term[languageIndex]
                    }
                    {
                      window.innerWidth < 1125 &&
                      <div className="term-add-mobile"><i className="fa fa-plus"></i></div>
                    }
                  </div>
                </div>
              </span>
              :
              <span className="term-already-hoverable" key={'term-already-hoverable-' + key}>
                { match[2] }
              </span>
          }
          <span className='term-gap' key={'term-gap-2-' + key}>
            { match[3] }
          </span>
        </div>
      );
    }
  }];
  let chapterText = chapter.text;
  return processString(config)(chapterText);
};

const TerminologyBasic = props => {
  const languagesClone = cloneDeep(props.languages);
  const languageIndex = languagesClone.findIndex(language =>
    language === props.currentLanguage) + 1;
  const questions = props.chapter.questions.map((question, idx) =>
    <li key={idx}> { question } </li>);
  return (
    <div className="terminology-basic">
      <div className="chapter-title">
        <p>
          {
            props.chapter.title
          }
        </p>
      </div>
      <div className="chapter-text">
        {
          processText(props, languageIndex)
        }
      </div>
      <div className="horizontal-line"></div>
      <div className="chapter-questions">
        <div className="chapter-questions-header">
          <i className="fa fa-question-circle fa-2x"></i>
          <h3>Pytania do tekstu</h3>
        </div>
        <ul>
          {
            questions
          }
        </ul>
      </div>
    </div>
  );
};

export default TerminologyBasic;
