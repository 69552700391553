import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

class OfferOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frontSide: true
    };
  }
  buy = () => {
    this.props.history.push('/register');
  }
  render() {
    const listElements = this.props.listElements.map((listElement, idx) => (
      <li key={`list-element-${idx}`}>
        <i className="fa-li fa fa-check"></i>
        <span>{ listElement }</span>
      </li>
    ));
    return (
      <div className={classNames('offer-option-container', {'best-offer-option': this.props.title === 'PAKIET'})}
        onMouseLeave={() => this.setState({frontSide: true})}
        onClick={() => !this.state.frontSide && this.setState({frontSide: true})}>
        <div className="offer-option" style={!this.state.frontSide ? {'transform': 'rotateY(180deg)'} : {}}>
          <div className="offer-front">
            <h4>{ this.props.title }</h4>
            <h5>{ this.props.subtitle }</h5>
            <div className="offer-line">
            </div>
            <div className="offer-verte" onClick={() => this.setState({frontSide: false})}>
              <div className="offer-verte-text">VERTE</div>
              <div className="offer-verte-icon">
                <i title="Zobacz więcej" className={classNames(['fa' ,'fa-undo-alt'])}></i>
              </div>
            </div>
            <div className="list-container">
              <ul className="fa-ul">{ listElements }</ul>
            </div>
            <div className="price-container">
              <div onClick={this.props.price !== 'JUŻ WKRÓTCE' ? this.buy : undefined} className={classNames('price', {'disabled': this.props.price === 'JUŻ WKRÓTCE'})}>
                <div className={classNames('present-price')}>
                  {
                    this.props.price === 'JUŻ WKRÓTCE' ? this.props.price :
                      this.props.price + ' zł'
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="offer-back">
            <div className="description">
              {
                this.props.back
              }
              <div className="back-list-container">
                <ul className="back-list">
                  {
                    this.props.backList && this.props.backList.map((el, idx) => (
                      <li key={`back-list-element-${idx}`}>
                        <i className="fa-li fa fa-check"></i>
                        <span>{ el }</span>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
            <div className="caption">
              {
                this.props.caption
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OfferOption);
