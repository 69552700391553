import React from 'react';
import { FacebookProvider, Like } from 'react-facebook';

import './Footer.scss';
import Logo from '../../shared/components/logo/Logo.jsx';

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="footer-rights">
        <Logo />
        <p>Wszystkie prawa zastrzeżone © 2018 DyktandoLekarza</p>
        <p>Design i wykonanie: <a href="https://www.linkedin.com/in/jakub-wojtyra-07b783129/" rel="noopener noreferrer" target="_blank">frozenfroggie</a></p>
        <img
          className="paypal"
          src={require('assets/banner_pl_payments_by_pp_165x56.png')}
          alt="Płatności z PayPal" />
      </div>
      <div className="first-column">
        <div className="footer-contact">
          <h3>Kontakt</h3>
          <p>dyktandolekarza@gmail.com</p>
        </div>
        <div className="footer-materials">
          <h3>Materiały pomocnicze</h3>
          <p><a href="https://drive.google.com/drive/folders/1ah8kY_ZJVXbxN0rClCnTvcHK-YMTZVdd" rel="noopener noreferrer" target="_blank">Google Drive</a></p>
          <p><a href="https://www.facebook.com/groups/291270561038032/" rel="noopener noreferrer" target="_blank">Grupa "Nostryfikacja dyplomu medycznego w Polsce" na Facebooku</a></p>
        </div>
      </div>
      <div className="second-column">
        <div className="footer-terms">
          <h3>Regulamin</h3>
          <a href="/documents/terms.pdf" target="_blank">regulamin serwisu dyktandolekarza.pl</a>
        </div>
        <div className="footer-social">
          <div className="footer-social-title">
            <h3>Social media</h3>
            <a title="Fanpage Dyktando Lekarza" href="https://www.facebook.com/Dyktando-Lekarza-2411666295574426" rel="noopener noreferrer" target="_blank">
              <i className="fab fa-2x fa-facebook-square"></i>
            </a>
          </div>
          <FacebookProvider appId="718891685142895">
            <Like href="https://www.facebook.com/Dyktando-Lekarza-2411666295574426" width="250" colorScheme="dark" showFaces share />
          </FacebookProvider>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
