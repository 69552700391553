import React from 'react';

const AboutDescription = props => {
  return (
    <div className="about-description">
      {props.children}
    </div>
  );
};

export default AboutDescription;
