import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './RegisterSummaryPage.scss';

class RegisterSummaryPage extends Component {
  render() {
    return (
      <div className="content">
        <i className="fa fa-3x fa-check-circle"></i>
        <h2>Gratulacje, konto zostało zalożone!</h2>
        <h4>Dziękujemy, na podany przy rejestracji adres e-mail wysłaliśmy potwierdzenie rejestracji i potrzebne wskazówki.</h4>
        <Link to="/">
          <button className="homepage-button">
              Przejdź do strony głównej
          </button>
        </Link>
      </div>
    );
  }
}

export default RegisterSummaryPage;
