import { LEARN_START, LEARN_END, CHECK_ANSWER, NEXT_TERM, SHOW_RESULTS } from '../../constants/actionTypes';

export const initialState = {
  answerSelected: undefined,
  isAnswerCorrect: false,
  termsToLearn: 0,
  currentTermIdx: 0,
  learn: false,
  termsShuffled: [],
  answersShuffled: [],
  correctAnswers: 0,
  showResults: false,
  totalAnswers: 0
};

const personalDictionaryLearnReducer = (state = initialState, action) => {
  switch(action.type)  {
  case LEARN_START:
    return {
      ...state,
      learn: true,
      termsShuffled: action.termsShuffled,
      answersShuffled: action.answersShuffled,
      termsToLearn: action.termsShuffled.length,
      totalAnswers: 0
    };
  case LEARN_END:
    return {
      ...state,
      learn: false,
      answerSelected: undefined,
      isAnswerCorrect: false,
      currentTermIdx: 0,
      answersShuffled: [],
      correctAnswers: 0,
      showResults: false
    };
  case CHECK_ANSWER:
    return {
      ...state,
      totalAnswers: state.totalAnswers + 1,
      answerSelected: action.answer,
      isAnswerCorrect: action.answer === action.correctAnswer,
      correctAnswers: action.answer === action.correctAnswer ? state.correctAnswers + 1 : state.correctAnswers
    };
  case NEXT_TERM:
    return {
      ...state,
      currentTermIdx: state.currentTermIdx + 1,
      answerSelected: undefined,
      isAnswerCorrect: false,
      answersShuffled: action.answersShuffled
    };
  case SHOW_RESULTS:
    return {
      ...state,
      showResults: true
    };
  default:
    return state;
  }
};

export default personalDictionaryLearnReducer;
