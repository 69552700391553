import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './SelfTutorial.scss';

import Step1 from './steps/Step1.jsx';
import Step2 from './steps/Step2.jsx';
import Step3 from './steps/Step3.jsx';
import Step4 from './steps/Step4.jsx';
import Step5 from './steps/Step5.jsx';

class SelfTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDictionary: {
        height: 400,
        width: 300
      },
      selfTutorialStyle: {
      },
      step: 1,
      open: true
    };
  }
  nextStep = () => {
    this.setState(prevState => ({
      step: prevState.step + 1
    }));
  }
  previousStep = () => {
    this.setState(prevState => ({
      step: prevState.step - 1
    }));
  }
  toogleTutorialOpen = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }
  render() {
    const { open } = this.state;
    const { terms } = this.props.personalDictionaryState;
    const progressPercentage = this.state.step / 5 * 100;
    return (
      <div className={classNames(
        'tutorial',
        {'tutorial-open': open},
        {'tutorial-close': !open}
      )}>
        <div className="label"
          onClick={this.toogleTutorialOpen}
          style={!open ? {left: '0%'} : {left: '-100%'}}>
          <i className="fa fa-graduation-cap" ></i>
        </div>
        <div className="self-tutorial-container"
          style={open ? {opacity: '1'} : {opacity: '0.1'}}>
          <i className="fa fa-times-circle" title="zminimalizuj"
            onClick={this.toogleTutorialOpen}></i>
          <div className="self-tutorial-title">
            <h3>SAMOUCZEK</h3>
          </div>
          <div className="self-tutorial-progress-container">
            <div className="self-tutorial-progress">
              <div className="progress-bar"
                style={{width: progressPercentage + '%'}}></div>
            </div>
          </div>
          <div className="step" style={
            open ? {transform: 'translateX(0px)'} : {transform: 'translateX(-150px)'}
          }>
            {
              this.state.step === 1 && <Step1 />
            }
            {
              this.state.step === 2 && <Step2 />
            }
            {
              this.state.step === 3 && <Step3 />
            }
            {
              this.state.step === 4 && <Step4 />
            }
            {
              this.state.step === 5 && <Step5 />
            }
          </div>
          <div className="buttons">
            {
              this.state.step > 1 &&
              <div className={classNames(['next'])}
                onClick={this.previousStep}>
                <div>
                  <span>Powrót</span>
                </div>
              </div>
            }
            {
              this.state.step < 5 ?
                <div className={classNames([
                  'next',
                  {'next-lock': this.state.step === 3 && terms.length < 4}
                ])}
                onClick={(this.state.step === 3 && terms.length < 4) || this.nextStep}>
                  <div>
                    <span>Dalej</span>
                    <i className="fa fa-play" title="dalej"></i>
                  </div>
                </div>
                :
                <div className={classNames([
                  'next',
                  {'next-lock': this.state.step === 3 && terms.length < 4}
                ])}
                onClick={() => this.setState({open: false})}>
                  <div>
                    <span>Zakończ</span>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  personalDictionaryState: store.personalDictionaryReducer
});

export default connect(mapStateToProps, {})(SelfTutorial);
