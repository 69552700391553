import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from './shared/actions/authActions';

import './App.scss';

import RegisterPage from './registerPage/RegisterPage.jsx';
import LoginPage from './loginPage/LoginPage.jsx';
import ResetPassword from './loginPage/resetPassword/ResetPassword.jsx';
import ResetPasswordNew from './loginPage/resetPassword/resetPasswordNew/ResetPasswordNew.jsx';
import LandingPage from './landingPage/LandingPage.jsx';
import CoursePage from './coursePage/CoursePage.jsx';
import NotFound from './notFound/NotFound.jsx';
import requireAuth from './utils/requireAuth.jsx';

export class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className='App' id='app'>
          <Switch>
            <Route path="/" exact component={ LandingPage } />
            <Route path="/demo" exact component={ CoursePage } />
            <Route path="/course" component={ requireAuth(CoursePage, 'user') } />
            <Route path="/login/reset-password/new" component={ ResetPasswordNew } />
            <Route path="/login/reset-password" component={ ResetPassword } />
            <Route path="/login" exact component={ LoginPage } />
            <Route path="/register" component={ RegisterPage } />
            <Route component={ NotFound } />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default connect(null, {logout})(App);
