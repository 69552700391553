import { combineReducers } from 'redux';

import authReducer from './authReducer';
import personalDictionaryReducer from '../coursePage/reducers/personalDictionaryReducer';
import personalDictionaryLearnReducer from '../coursePage/reducers/personalDictionaryLearnReducer';
import courseReducer from '../coursePage/reducers/courseReducer';

const combinedReducers = combineReducers({
  personalDictionaryReducer,
  personalDictionaryLearnReducer,
  authReducer,
  courseReducer
});

export default combinedReducers;
