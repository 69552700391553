import * as authActionTypes from '../constants/authActionTypes';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  user: {},
  isAuthenticated: false,
  errors: []
};

const authReducer = (state = initialState, action) => {
  switch(action.type) {
  case authActionTypes.SAVE_USER:
    return {
      ...state,
      user: action.user,
      isAuthenticated: !isEmpty(action.user)
    };
  case authActionTypes.SAVE_ERRORS:
    return {
      ...state,
      errors: action.user
    };
  default:
    return state;
  }
};

export default authReducer;
