import React from 'react';
import classNames from 'classnames';

import './MessageBox.scss';

const MessageBox = (props) => {
  return (
    <div className={classNames([
      'message',
      {'message-add': props.message.type === 'add'},
      {'message-remove': props.message.type === 'remove'},
      {'message-warning': props.message.type === 'warning'},
      {'message-hidden': !props.message.value}
    ])}>
      { props.message.value && props.message.value }
      <i className="fa fa-times" onClick={props.hideMessageNow}></i>
    </div>
  );
};

export default MessageBox;
