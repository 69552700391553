import React from 'react';
import classNames from 'classnames';

import './Answer.scss';

const Answer = (props) => {
  return (
    <div className={classNames([
      'answer',
      {'answer-correct-show': props.correctAnswer === props.term && props.answerSelected && !props.isAnswerCorrect},
      {'answer-correct': props.answerSelected === props.term && props.isAnswerCorrect},
      {'answer-wrong': props.answerSelected === props.term && !props.isAnswerCorrect}
    ])}
    onClick={() => props.answerSelected || props.checkAnswer(props.term)}>
      { props.term }
    </div>
  )
}

export default Answer;
