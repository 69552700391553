import React from 'react';

const Step2 = () => {
  return (
    <div className="self-tutorial-text">
      <p> Zapoznaj się z terminologią do działu </p>
      <p>Możesz zmienić język tłumaczenia klikając na ikonę
        <div className="dropdown">
          <img src={require('assets/flag-uk.png')}
            alt={'uk flag'} className="flag" />
          <i className="fa fa-caret-down"></i>
        </div>
      i wybierając odpowiednią flagę
      </p>
    </div>
  )
}

export default Step2;
