import React from 'react';

import './Logo.scss';

const Logo = props => {
  return (
    <div className="logo" onClick={() => props.goHome && props.goHome()} >
      <div className="pill">
        <div className="pill-left">
          <div className="pill-left-shadow"></div>
        </div>
        <div className="pill-right">
          <div className="pill-right-shadow"></div>
        </div>
      </div>
      <div className="logo-label">DyktandoLekarza</div>
    </div>
  );
};

export default Logo;
