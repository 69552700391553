import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { shuffle, sampleSize, cloneDeep } from 'lodash';

import './PersonalDictionary.scss';
import { close, dragStart, showResults, back, handleRemoveTerm, learnStart,
  learnEnd, checkAnswer, nextTerm } from '../../../actions/personalDictionaryActions';
import PersonalDictionaryWidget from './widget/PersonalDictionaryWidget.jsx';
import PersonalDictionaryFull from './full/PersonalDictionaryFull.jsx';
import PersonalDictionaryLearn from './learn/PersonalDictionaryLearn.jsx';
import PersonalDictionaryResults from './results/PersonalDictionaryResults.jsx';
import Term from './term/Term.jsx';

class PersonalDictionary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionsLanguage: 'pl',
      answersLanguage: props.personalDictionaryState.currentLanguage,
      openDictionary: {
        height: 450,
        width: 300
      },
      personalDictionaryStyle: {
      }
    };
    this.widgetMoveRef = React.createRef();
    this.tutorialRef = React.createRef();
  }
  componentDidMount() {
    this.setState({
      questionsLanguage: 'pl',
      answersLanguage: this.props.personalDictionaryState.currentLanguage
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const currentLanguage = this.props.personalDictionaryState.currentLanguage;
    if(prevProps.personalDictionaryState.currentLanguage !== currentLanguage) {
      prevState.questionsLanguage !== 'pl'
        ?
        this.setState({
          questionsLanguage: currentLanguage,
          answersLanguage: 'pl'
        })
        :
        this.setState({
          questionsLanguage: 'pl',
          answersLanguage: currentLanguage
        });
    }
  }
  componentWillUnmount() {
    this.props.personalDictionaryState.open && this.props.close();
  }
  mouseDown = (ev) => {
    if(this.props.personalDictionaryState.open) {
      this.props.dragStart(ev);
    }
  }
  openDictionary = (ev) => {
    const { currentX, currentY } = this.props.personalDictionaryState;
    const { height, width } = this.state.openDictionary;
    if(!this.props.personalDictionaryState.open && this.widgetMoveRef.current !== ev.target) {
      this.props.close();
      if(currentY + height > window.innerHeight && currentX + width > window.innerWidth) {
        this.setState({
          personalDictionaryStyle: {
            transform: 'translateY(calc(-100% + 70px)) translateX(calc(-100% + 70px))'
          }
        });
      } else if(currentY + height > window.innerHeight) {
        this.setState({
          personalDictionaryStyle: {
            transform: 'translateY(calc(-100% + 70px))'
          }
        });
      } else if(currentX + width > window.innerWidth) {
        this.setState({
          personalDictionaryStyle: {
            transform: 'translateX(calc(-100% + 70px))'
          }
        });
      } else {
        this.setState({
          personalDictionaryStyle: {
            transform: 'translateY(0px)'
          }
        });
      }
    }
  }
  generateAndShuffleAnswers = (termsShuffled, termIdx) => {
    let termsShuffledClone = cloneDeep(termsShuffled);
    termsShuffledClone = termsShuffledClone.filter(term => term[0] !== termsShuffled[termIdx][0]);
    const answers = sampleSize(termsShuffledClone, 3);
    answers.push(termsShuffled[termIdx]);
    const answersShuffled = shuffle(answers);
    return answersShuffled;
  }
  learnStart = (ev, terms) => {
    ev.preventDefault();
    let { currentTermIdx } = this.props.personalDictionaryLearnState;
    if(terms.length < 4) {
      this.props.showWarningMessage();
      return;
    }
    const termsShuffled = shuffle(terms);
    const answersShuffled = this.generateAndShuffleAnswers(termsShuffled, currentTermIdx);
    this.props.learnStart(termsShuffled, answersShuffled);
  }
  nextTerm = () => {
    const { termsShuffled, currentTermIdx } = this.props.personalDictionaryLearnState;
    const nextTermIdx = currentTermIdx + 1;
    if(nextTermIdx >= termsShuffled.length) {
      this.props.showResults();
      return;
    }
    const answersShuffled = this.generateAndShuffleAnswers(termsShuffled, nextTermIdx);
    this.props.nextTerm(answersShuffled);
  }
  replaceLanguages = () => {
    this.setState(prevState => ({
      questionsLanguage: prevState.answersLanguage,
      answersLanguage: prevState.questionsLanguage
    }));
  }
  render() {
    const { demo } = this.props;
    const { terms, newTerms, currentX, currentY, open, languages,
      currentLanguage } = this.props.personalDictionaryState;
    const { totalAnswers, correctAnswers, showResults, answersShuffled, learn,
      termsShuffled, anyAnswerSelected, answerSelected, isAnswerCorrect,
      termsToLearn, currentTermIdx } = this.props.personalDictionaryLearnState;
    const { height, width } = this.state.openDictionary;
    const languagesClone = cloneDeep(languages);
    const languageIndex = languagesClone.findIndex(language => language === currentLanguage) + 1;
    const { questionsLanguage, answersLanguage } = this.state;
    const termComponents = terms && terms.sort().map((term, idx) => (
      <Term key={`${term[0]}-${idx}`}
        languageIndex={languageIndex}
        deleteFromDictionary={() => this.props.handleRemoveTerm(term, demo)}
        term={term} />
    ));
    return (
      <div className={classNames([
        'personal-dictionary',
        {'personal-dictionary-widget': !open},
        {'personal-dictionary-open': open}
      ])}
      style={{
        ...this.state.personalDictionaryStyle,
        left: currentX,
        top: currentY - 90,
        height: open ? height : 70,
        width: open ? width : 70}
      }
      onMouseDown={open ? this.mouseDown : undefined}
      onClickCapture={this.openDictionary}>
        <div className={classNames(
          'personal-dictionary-content',
          {'personal-dictionary-open-content': open},
          {'personal-dictionary-widget-content': !open}
        )}>
          {
            open ?
              learn ?
                showResults ?
                  <PersonalDictionaryResults
                    totalAnswers={totalAnswers}
                    correctAnswers={correctAnswers}
                    close={this.props.close}
                    back={this.props.back}
                    learnEnd={this.props.learnEnd} />
                  :
                  <PersonalDictionaryLearn
                    currentLanguage={currentLanguage}
                    answersLanguage={answersLanguage}
                    questionsLanguage={questionsLanguage}
                    languageIndex={languageIndex}
                    anyAnswerSelected={anyAnswerSelected}
                    answerSelected={answerSelected}
                    isAnswerCorrect={isAnswerCorrect}
                    termsShuffled={termsShuffled}
                    answersShuffled={answersShuffled}
                    termsToLearn={termsToLearn}
                    currentTermIdx={currentTermIdx}
                    showResults={this.props.showResults}
                    nextTerm={this.nextTerm}
                    close={this.props.close}
                    back={this.props.back}
                    checkAnswer={(answer, correctAnswer) =>
                      this.props.checkAnswer(answer, correctAnswer)} />
                :
                <PersonalDictionaryFull
                  learnStart={(ev) => this.learnStart(ev, terms)}
                  termComponents={termComponents}
                  close={this.props.close}
                  showWarningMessage={this.showWarningMessage}
                  languages={languages}
                  currentLanguage={currentLanguage}
                  questionsLanguage={questionsLanguage}
                  answersLanguage={answersLanguage}
                  replaceLanguages={this.replaceLanguages}
                />
              :
              <PersonalDictionaryWidget
                widgetMoveRef={this.widgetMoveRef}
                newTerms={newTerms}
                dragStart={this.props.dragStart} />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  personalDictionaryState: store.personalDictionaryReducer,
  personalDictionaryLearnState: store.personalDictionaryLearnReducer
});

export default connect(mapStateToProps, {showResults, nextTerm,
  handleRemoveTerm, close, back, dragStart, learnStart, learnEnd,
  checkAnswer})(PersonalDictionary);
