import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import '../shared/styles/Forms.scss';
import './RegisterPage.scss';

import Navigation from '../shared/components/navigation/Navigation.jsx';
import RegisterSummaryPage from './registerSummaryPage/RegisterSummaryPage.jsx';

import validateInput from '../utils/clientValidation';
import { signup } from '../shared/actions/authActions';

export class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        email: '',
        password: '',
        passwordConfirmation: ''
      },
      isValid: false,
      userAlreadyExists: false,
      isLoading: false,
      email: undefined,
      password: undefined,
      passwordConfirmation: undefined,
    };
  }
  componentDidMount() {
    window.addEventListener('keypress', this.onKeyPress);
  }
  componentWillUnmount() {
    window.removeEventListener('keypress', this.onKeyPress, false);
  }
  onKeyPress = e => {
    const key = e.which || e.keyCode;
    if (key === 13) {
      this.onSubmit();
    }
  }
  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    this.setState({ errors, isValid });
    return isValid;
  }
  onSubmit = e => {
    e && e.preventDefault();
    if(this.isValid()) {
      this.setState({ errors: {}, isLoading: true });
      this.props.signup(this.state)
        .then(() => {
          this.props.history.push('/register/summary');
        })
        .catch(response => {
          const errors = response.data.errors;
          const status = response.status;
          if(status === 422) {
            errors.forEach(error => {
              this.setState(prevState => ({
                errors: {
                  ...prevState.errors,
                  [error.param]: error.msg
                },
                isLoading: false
              }));
            });
          } else if(status === 409 && response.data.error.type === 'user-already-exists') { // email already exists
            this.setState(prevState => ({
              errors: {
                ...prevState.errors,
                email: response.data.error.message
              },
              isLoading: false
            }));
          }
        });
    }
  }
  onChange = e => {
    if(e.target.name === 'email') {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          email: ''
        }
      }));
    }
    this.setState({[e.target.name]: e.target.value}, () => {
      this.isValid();
    });
  }
  render() {
    const { errors, isLoading, isValid } = this.state;
    return (
      <div className='wrapper'>
        <Navigation history={this.props.history} />
        <Route path={`${this.props.match.path}/summary`} component={RegisterSummaryPage} />
        <Route exact path={this.props.match.path} render={() => (
          <form className='form' onSubmit={e => e.preventDefault()}>
            <div className='form-header'>
              <h3>Zacznij od utworzenia konta</h3>
            </div>

            <div className="input-container">
              <div className="username">
                <i className="fa fa-user"></i>
                <input
                  onChange={this.onChange}
                  className="credential-input"
                  type="email"
                  name="email"
                  placeholder="Twoj email"
                  aria-label="Email"/>
              </div>
              {
                errors && errors['email']
                && <div className="error-message">{ errors['email'] }</div>
              }

              <div className="password">
                <i className="fa fa-unlock"></i>
                <input
                  onChange={this.onChange}
                  className="credential-input"
                  type="password"
                  name="password"
                  autoComplete="on"
                  placeholder="Twoje haslo"
                  aria-label="Password"/>
              </div>
              {
                errors && errors['password']
                && <div className="error-message">{ errors['password'] }</div>
              }

              <div className="password">
                <i className="fa fa-unlock"></i>
                <input
                  onChange={this.onChange}
                  className="credential-input"
                  type="password"
                  name="passwordConfirmation"
                  autoComplete="on"
                  placeholder="Powtórz haslo"
                  aria-label="Password"/>
              </div>
              {
                errors && errors['passwordConfirmation']
                && <div className="error-message">{ errors['passwordConfirmation'] }</div>
              }
            </div>

            <button type="submit" name=""
              className={classNames('action-button', {'action-button-disabled': isLoading || !isValid})}
              disabled={isLoading || !isValid}
              onClick={this.onSubmit}>
              {
                isLoading ?
                  <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                  :
                  'ZAREJESTRUJ'
              }
            </button>

            <div className="horizontal-line" style={{top: -20}}></div>

            <div className="register-panel">
              Masz już konto?
              <Link to="login" className="register">
                <span> Zaloguj się </span>
              </Link>
              <i className='fa fa-sign-in-alt'></i>
            </div>
          </form>
        )}
        />
      </div>
    );
  }
}

export default connect(null, {signup})(RegisterPage);
