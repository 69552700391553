import React from 'react';
import classNames from 'classnames';

import './PersonalDictionaryLearn.scss';
import Answer from './answer/Answer.jsx';

const PersonalDictionaryLearn = (props) => {
  const currentTerm = props.termsShuffled[props.currentTermIdx];
  const question = props.questionsLanguage === 'pl' ? currentTerm[0] : currentTerm[props.languageIndex];
  const correctAnswer = props.answersLanguage === 'pl' ? currentTerm[0] : currentTerm[props.languageIndex];
  return (
    <div className="learn-container">
      <i className="fa fa-arrow-left" title="cofnij" onClick={props.back}></i>
      <span className="terms-to-learn">
        { props.currentTermIdx + 1 + '/' + props.termsToLearn }
      </span>
      <i className="fa fa-times-circle" title="zminimalizuj" onClick={props.close}></i>
      <div className="question">
        {
          question
        }
      </div>
      <div className="answers">
        {
          props.answersShuffled.map((term, idx) => (
            <Answer key={idx}
              correctAnswer={correctAnswer}
              answerSelected={props.answerSelected}
              isAnswerCorrect={props.isAnswerCorrect}
              checkAnswer={(answer) => props.checkAnswer(answer, correctAnswer)}
              term={props.answersLanguage === 'pl' ? term[0] : term[props.languageIndex]} />
          ))
        }
      </div>
      <div className="buttons">
        <div className="learn-end"
          onClick={props.showResults}>
          <span>Zakończ</span>
        </div>
        <div className={classNames(['next', {'next-lock': !props.answerSelected}])}
          onClick={props.answerSelected && props.nextTerm}>
          <div>
            <span>Dalej</span>
            <i className="fa fa-play" title="dalej"></i>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalDictionaryLearn;
