import React, { Component } from 'react';
import classNames from 'classnames';

import './LandingNavigation.scss';
import Logo from '../../shared/components/logo/Logo.jsx';
import HamburgerMenu from './hamburgerMenu/HamburgerMenu.jsx';
import LandingNavigationTabs from './landingNavigationTabs/LandingNavigationTabs.jsx';

class LandingNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false,
      shouldExpandMenu: false,
      laptopBreakpoint: 1125 // make sure that laptop breakpoint in mixins is equal
    };
  }
  componentDidMount() {
    if(window.innerWidth >= this.state.laptopBreakpoint && !this.state.shouldExpandMenu) {
      this.toogleExpandMenu();
    }
    window.addEventListener('resize', this.handleResizing);
    window.addEventListener('scroll', () => {
      if(window.scrollY + 80 >= window.innerHeight && !this.state.stickyNav) {
        this.setState({stickyNav: true});
      } else if(window.scrollY + 80 < window.innerHeight && this.state.stickyNav) {
        this.setState({stickyNav: false});
      }
    });
  }
  handleResizing = () => {
    if((window.innerWidth >= this.state.laptopBreakpoint && !this.state.shouldExpandMenu) || (window.innerWidth < this.state.laptopBreakpoint && this.state.shouldExpandMenu)) {
      this.toogleExpandMenu();
    }
  }
  toogleExpandMenu = () => {
    this.setState(prevState => ({
      shouldExpandMenu: !prevState.shouldExpandMenu
    }));
  }
  tabSelected = (tab) => {
    if(window.innerWidth < this.state.laptopBreakpoint && this.state.shouldExpandMenu) {
      this.toogleExpandMenu();
    }
    this.props.scrollToTab(tab);
  }
  render() {
    const { shouldExpandMenu, stickyNav } = this.state;
    const navClasses = classNames('landing-navigation', {
      'landing-navigation-sticky': stickyNav
    });
    return (
      <nav className={navClasses} id="nav">
        <Logo select={(tab) => this.tabSelected(tab)} />
        {
          shouldExpandMenu && !this.props.disableTabs
          &&
          <LandingNavigationTabs
            select={(tab) => this.tabSelected(tab)}
            isAuthenticated={this.props.isAuthenticated}
            isSticky={this.state.stickyNav} />
        }
        <HamburgerMenu
          shouldExpandMenu={shouldExpandMenu}
          toogleExpandMenu={this.toogleExpandMenu} />
      </nav>
    );
  }
}

export default LandingNavigation;
