import React from 'react';

import './PersonalDictionaryResults.scss';

const PersonalDictionaryResults = (props) => {
  const correctAnswersPercentage = Math.round(props.correctAnswers * 100 / props.totalAnswers);
  return (
    <div className="results-container">
      <i className="fa fa-arrow-left" title="cofnij" onClick={props.back}></i>
      <i className="fa fa-times-circle" title="zminimalizuj" onClick={props.close}></i>
      <p>Przećwiczone słowa:
        <b>{ ` ${props.totalAnswers}` }</b>
      </p>
      {
        props.totalAnswers !== 0 &&
        <div>
          <p>Prawidłowe odpowiedzi:
            <b>{ ` ${props.correctAnswers} (${correctAnswersPercentage}%)` }</b>
          </p>
          <p>Nieprawidłowe odpowiedzi:
            <b>{ ` ${props.totalAnswers - props.correctAnswers} (${100 - correctAnswersPercentage}%)` }</b>
          </p>
        </div>
      }
      <div className="learn-end"
        onClick={props.learnEnd}>
        <div>
          <span>Zakończ</span>
        </div>
      </div>
    </div>
  )
}

export default PersonalDictionaryResults;
