import React from 'react';

import './Error.scss';

const Error = () => {
  return (
    <div className="error-container">
      <div className="error">
        <h1>500</h1>
        <h2>Przepraszamy, coś poszło nie tak, spróbuj ponownie później.</h2>
      </div>
    </div>
  )
}

export default Error;
