import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import axiosDyktando from '../../../utils/axiosDyktando';
import { logout } from '../../../shared/actions/authActions';

import './Admin.scss';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filteredUsers: [],
      dropdown: {},
      textInputValue: '',
      numberInputValue: 0,
      availabilityRequest: {
        pending: false,
        success: false,
        error: false
      },
      couponsRequest: {
        pending: false,
        success: false,
        error: false
      },
      coupons: [],
      couponInputText: '',
      couponInputNumber: 0,
      couponInputDate: new Date()
    };
  }
  componentDidMount() {
    axiosDyktando.get('/api/course')
      .then(res => {
        this.setState({
          numberInputValue: res.data.basic.availability,
          coupons: res.data.basic.coupons
        });
      })
      .catch(err => {
        if(err === 'refresh token expired') {
          this.props.logout();
        }
      });
    axiosDyktando.get('/api/admin/users')
      .then(res => {
        this.setState({
          users: res.data,
          filteredUsers: res.data
        });
      })
      .catch(err => {
        if(err === 'refresh token expired') {
          this.props.logout();
        }
      });
  }
  handleDropdownChange = (event, id) => {
    const dropdown = Object.assign({}, this.state.dropdown);
    dropdown[id] = event.target.value;
    this.setState({ dropdown });
  }
  handleTextInputChange = event => {
    const value = event.target.value;
    let users = Object.assign([], this.state.users);
    users = users.filter(user => user.email.includes(value));
    this.setState({
      textInputValue: value,
      filteredUsers: users
    });
  }
  handleNumberInputChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    });
  }
  addCoupon = event => {
    event.preventDefault();
    const { couponInputNumber, couponInputText, couponInputDate } = this.state;
    this.setState({
      couponsRequest: {
        pending: true,
        error: false
      }
    });
    axiosDyktando.post('/api/course/coupons', {
      name: couponInputText,
      promotion: couponInputNumber,
      expiration: couponInputDate
    })
      .then(res => {
        this.setState({
          coupons: res.data.course.basic.coupons,
          couponsRequest: {
            pending: false,
            success: true
          }
        });
      })
      .catch(err => {
        this.setState({
          couponsRequest: {
            pending: false,
            error: true
          }
        });
      });
  }
  deleteCoupon = couponId => {
    axiosDyktando.delete('/api/course/coupons/' + couponId)
      .then(res => {
        this.setState({
          coupons: res.data.course.basic.coupons
        });
      })
      .catch(err => {
        this.setState({
          availabilityRequest: {
            pending: false,
            error: true
          }
        });
      });
  }
  updateAvailability = event => {
    event.preventDefault();
    this.setState({
      availabilityRequest: {
        pending: true,
        success: false,
        error: false
      }
    });
    axiosDyktando.post('/api/course/availability', {quantity: this.state.numberInputValue})
      .then(res => {
        this.setState({
          numberInputValue: res.data.course.basic.availability,
          availabilityRequest: {
            pending: false,
            success: true
          }
        });
      })
      .catch(err => {
        this.setState({
          availabilityRequest: {
            pending: false,
            error: true
          }
        });
      });
  }
  activateCourse = id => {
    axiosDyktando.post('/api/admin/activate-course', {
      userId: id,
      courseLevel: 'basic',
      duration: this.state.dropdown[id] || 30
    }).then(res => {
      const user = res.data;
      let users = Object.assign([], this.state.users);
      const index = users.findIndex(el => el._id === user._id)
      users[index].course.basic.expiration = user.course.basic.expiration;
      this.setState({
        users
      });
    }).catch(err => {
      if(err === 'refresh token expired') {
        this.props.logout();
      }
    });
  }
  deactivateCourse = id => {
    axiosDyktando.post('/api/admin/deactivate-course', {
      userId: id,
      courseLevel: 'basic'
    }).then(res => {
      const user = res.data;
      let users = Object.assign([], this.state.users);
      const index = users.findIndex(el => el._id === user._id)
      users[index].course.basic.expiration = user.course.basic.expiration;
      this.setState({
        users
      });
    }).catch(err => {
      if(err === 'refresh token expired') {
        this.props.logout();
      }
    });
  }
  render() {
    const coupons = this.state.coupons.map(coupon => {
      const expirationDate = moment(coupon.expiration);
      const currentDate = moment();
      const isExpired = expirationDate.diff(currentDate) < 0;
      return (
        <tr key={coupon._id}>
          <td>{ coupon.name }</td>
          <td>
            { coupon.promotion } %
          </td>
          <td className={classNames({'expired': isExpired}, {'active': !isExpired})}>{ coupon.expiration } </td>
          <td>
            <input type="submit" value="Usuń" onClick={() => this.deleteCoupon(coupon._id)} />
          </td>
        </tr>
      )
    })
    const users = this.state.filteredUsers.map(user => {
      const expirationDate = moment(user.course.basic.expiration);
      const currentDate = moment();
      const isExpired = expirationDate.diff(currentDate) < 0;
      return (
        <tr key={user._id}>
          <td>{ user.email }</td>
          <td className={classNames({'expired': isExpired}, {'active': !isExpired})}>{ user.course.basic.expiration } </td>
          <td>
            <select name="duration" value={this.state.dropdown[user.id]} onChange={(event) => this.handleDropdownChange(event, user._id)}>
              <option value="30">30 dni</option>
              <option value="60">60 dni</option>
            </select>
          </td>
          <td>
            <input type="submit" value="Aktywuj" onClick={() => this.activateCourse(user._id)} />
            <input type="submit" value="Dezaktywuj" onClick={() => this.deactivateCourse(user._id)} />
          </td>
        </tr>
      )
    });
    return (
      <div className="admin-container">
        <h2>PANEL ADMINISTRACYJNY</h2>
        <div className="availability-container">
          <form onSubmit={this.updateAvailability}>
            <div className="form-availability">
              <label>Dostępność:</label>
              <input type="number"
                min="0"
                max="25"
                name="numberInputValue"
                onChange={this.handleNumberInputChange}
                value={this.state.numberInputValue} />
              <input type="submit"
                disabled={this.state.availabilityRequest.pending}
                className={classNames({'success': this.state.availabilityRequest.success, 'error': this.state.availabilityRequest.error, 'pending': this.state.availabilityRequest.pending})}
                value={this.state.availabilityRequest.success ? 'OK!' : this.state.availabilityRequest.pending ? 'Czekaj' : 'Akceptuj'} />
            </div>
          </form>
        </div>
        <hr/>
        <div className="coupon-container">
          <form onSubmit={this.addCoupon}>
            <div className="form-coupon">
              <label>Dodaj kupon:</label>
              <input type="text" name="couponInputText"
                maxLength="10"
                onChange={this.handleNumberInputChange}
                value={this.state.couponInputText} />
              <label>Podaj zniżkę (%):</label>
              <input type="number" name="couponInputNumber"
                min="5"
                max="99"
                onChange={this.handleNumberInputChange}
                value={this.state.couponInputNumber} />
              <label>Ważny do:</label>
              <input type="date" name="couponInputDate"
                onChange={this.handleNumberInputChange}
                value={this.state.couponInputDate} />
              <input type="submit"
                disabled={this.state.couponsRequest.pending}
                className={classNames({
                  'success': this.state.couponsRequest.success,
                  'error': this.state.couponsRequest.error,
                  'pending': this.state.couponsRequest.pending
                })}
                value={this.state.couponsRequest.success ? 'OK!' : this.state.couponsRequest.pending ? 'Czekaj' : 'Dodaj'} />
            </div>
          </form>
          {
            coupons.length > 0 ?
              <div className="table-coupons">
                <table>
                  <thead>
                    <tr>
                      <th>Kupon</th>
                      <th>Promocja</th>
                      <th>Ważny do</th>
                      <th>Akcja</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      coupons
                    }
                  </tbody>
                </table>
              </div>
              :
              coupons.pending ?
                <i className="fas fa-2x fa-cog fa-spin"></i>
                :
                <h3> brak wprowadzonych kuponów </h3>
          }
        </div>
        <hr/>
        <div className="users-container">
          <label htmlFor="inputFilter">Wyszukaj e-mail:</label>
          <input name="inputFilter" type='text'
            onChange={this.handleTextInputChange}
            value={this.state.textInputValue}
            className="input-text-filter" />
          {
            users.length > 0 ?
              <div className="table-users">
                <table>
                  <thead>
                    <tr>
                      <th>E-mail</th>
                      <th>Podstawy Terminologii</th>
                      <th>Czas trwania</th>
                      <th>Akcja</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      users
                    }
                  </tbody>
                </table>
              </div>
              :
              <i className="fas fa-2x fa-cog fa-spin"></i>
          }
        </div>
      </div>
    )
  }
}

export default connect(null, {logout})(Admin);
