import React from 'react';

const HamburgerMenu = props => {
  return (
    <div className="hamburger-menu">
      <button
        aria-label="Expand menu"
        className= { props.shouldExpandMenu ? 'hamburger close' : 'hamburger' }
        onClick={ props.toogleExpandMenu }>
        <span></span>
      </button>
    </div>
  );
};

export default HamburgerMenu;
