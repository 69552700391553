export const CHANGE_DICTIONARY_POSITION = 'CHANGE_DICTIONARY_POSITION';
export const TOOGLE_DICTIONARY_HOLD = 'TOOGLE_DICTIONARY_HOLD';
export const ADD_TERM = 'ADD_TERM';
export const DELETE_TERM = 'DELETE_TERM';
export const DELETE_ALL_TERMS = 'DELETE_ALL_TERMS';
export const LEARN_START = 'LEARN_START';
export const LEARN_END = 'LEARN_END';
export const CHECK_ANSWER = 'CHECK_ANSWER';
export const NEXT_TERM = 'NEXT_TERM';
export const SHOW_RESULTS = 'SHOW_RESULTS';
export const TOOGLE_LANGUAGE_DROPDOWN = 'TOOGLE_LANGUAGE_DROPDOWN';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';
export const SET_COURSES = 'SET_COURSES';
export const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE';
export const SAVE_PERSONAL_DICTIONARY = 'SAVE_PERSONAL_DICTIONARY';
