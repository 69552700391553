import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import './Dictionary.scss';

import LanguageDropdown from '../languageDropdown/LanguageDropdown.jsx';

class Dictionary extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  termOver = (term) => {
    if(document.getElementById(term[0])) {
      document.getElementById(term[0]).style.backgroundColor = 'rgba(223, 237, 249,.9)'
    }
  }
  termOut = (term) => {
    if(document.getElementById(term[0])) {
      document.getElementById(term[0]).style.backgroundColor = 'inherit'
    }
  }
  render() {
    const { languages, currentLanguage, showLanguageDropdown } = this.props;
    const languagesClone = cloneDeep(languages);
    const languageToSelect = languagesClone.filter(language => language !== currentLanguage);
    const languageIndex = languagesClone.findIndex(language => language === currentLanguage) + 1;
    return (
      <div className="dictionary">
        <div className="dictionary-header">
          <h3> SŁOWNIK </h3>
          <LanguageDropdown
            showLanguageDropdown={showLanguageDropdown}
            languageToSelect={languageToSelect}
            currentLanguage={currentLanguage}
            toogleLanguageDropdown={this.props.toogleLanguageDropdown}
            selectLanguage={(language) => this.props.selectLanguage(language)} />
        </div>
        <ul>
          {
            this.props.dictionary.sort().map((term, idx) => {
              return (
                <li
                  onClick={() => this.props.clickTerm(term)}
                  onMouseOver={() => this.termOver(term)}
                  onMouseOut={() => this.termOut(term)}
                  key={idx}>
                  <span>{term[0]}</span> - {term[languageIndex]}
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}

export default Dictionary;
