import { SET_COURSES, SET_CURRENT_COURSE } from '../../constants/actionTypes';

export const initialState = {
  courses: [],
  currentCourse: null
};

const courseReducer = (state = initialState, action) => {
  switch(action.type) {
  case SET_COURSES:
    return {
      ...state,
      courses: action.courses
    };
  case SET_CURRENT_COURSE:
    return {
      ...state,
      currentCourse: action.currentCourse
    };
  default:
    return state;
  }
};

export default courseReducer;
