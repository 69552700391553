import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

import './Faq.scss';

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [
        'Dla kogo jest ten kurs?',
        'Czego się nauczę na tym kursie?',
        'Na czym polega kurs "Podstawy terminologii"?',
        'Na czym polega kurs "Terminologia zaawansowana"?',
        'Jak uzyskać dostęp do kursów?',
        'Znam już trochę terminologię medyczną, czy jest sens wykupić tylko kurs "Terminologia zaawansowana"?',
        'Kiedy można będzie wykupić dostęp do kursu?',
        'Czy mogę udostępniać hasło i login innym po wykupieniu kursu?',
        'Skąd brałaś informacje żeby przygotować ten kurs?',
        'Czy kurs zawiera informacje, książki, materiały,  które są w bezpłatnym dostępie w internecie?',
        'Czy kurs gwarantuje że zaliczę egzamin ze znajomości języka polskiego dla lekarzy?',
        'Czy kurs gwarantuje że zdam egzaminy nostryfikacyjne?'
      ],
      answers: [
        'Kurs jest dedykowany lekarzom którzy planują nostryfikację dyplomu medycznego w Polsce i przygotowują się do Egzaminu ze znajomości języka polskiego dla lekarzy.',
        'Terminologii medycznej języka polskiego.',
        `Kurs „Podstawy terminologii” to kurs dla osób, które zaczynają uczyć się terminologii medycznej. Opracowałam 14  podstawowych bloków tematycznych, każdy z nich składa się z:
          1. Tekst z podstawową wiedzą z anatomii i fizjologii z danego układu organizmu, najczęstsze objawy i najczęstsze jednostki chorobowe. Tekst jest spójny, treściowy, czytelny, zawiera podstawową terminologię z danej dziedziny, w nawiasach często znajdziesz łacińską lub grecką nazwę.
          2. 5 otwartych pytań do tekstu
          3. Słownik do danego tekstu bazowy
          4. Interaktywny słownik użytkownika, który będziesz tworzyć sam, zapisując nieznane ci słowa, następnie ćwiczyć słowa on-line
        *Z uwagi na trudność polskiej terminologii medycznej zalecany jest poziom znajomości języka polskiego na poziomie A2.`,
        `Kurs „Terminologia zaawansowana” ma na celu pomóc ci w przygotowaniu do egzaminu ze znajomości języka polskiego dla lekarzy. Opracowałam najbardziej popularne tematy z różnych dziedzin medycyny. Kurs składa się z autorskich dyktando na różne tematy medyczne, nagrane przez rodowitych Polaków z dobrą dykcją. Kurs składa się z kilkunastu bloków tematycznych, każdy z nich wygląda następująco:
          1. Słownik z terminologią do zapoznania się
          2. Nagranie dyktando – słuchasz 1 raz
          3. Blok z 5 pytań do dyktando – odpowiadasz i sprawdzasz
          4. Nagranie dyktando – piszesz dyktando na czystej kartce A4
          5. Pojawia się dostęp do tekstu dyktando - sprawdzasz
          6. Spisujesz na kartce słowa, które napisałeś niepoprawnie
        *Z uwagi na trudność polskiej terminologii medycznej zalecany jest poziom znajomości języka polskiego na poziomie A2-B1 oraz znajomość podstawowej terminologii medycznej`,
        'Na stronie głównej możesz spróbować wersję testową. Pełny dostęp można wykupić w zakładce "Oferta". Można kupić każdy kurs osobno lub razem, na różną ilość dni dostępu.',
        'Tak, jak najbardziej. Żebyś w pełni skorzystał/a z kursu "Terminologia zaawansowana" zalecana jest podstawowa znajomość z medyczną terminologią polską iż w tym kursie skupiamy się i więcej czasu poświęcamy na dyktando i rozumienie tekstu.',
        'W styczniu planujemy wystartować z kursem "Podstawy terminologii". Zapisz się do newslettera a poinformujemy Cię jak zacznie się sprzedaż. Ostateczna data startu kursu "Zaawansowana terminologia" jest nieznana, bądź z nami na bieżąco.',
        'Nie. Platforma chroniona jest prawem autorskim. Udostępnianie loginu i hasła jest zakazane. W przypadku wykrycia wyżej wymienionych działań konto zostanie zablokowane.',
        'Wszystkie teksty do kursu "Podstawy terminologii" napisałam sama, używając odpowiednich podręczników, było ich ponad 20. Ponieważ sama zdawałam egzamin ze znajomości języka polskiego a obecnie pracuję jako lekarz, wiedziałam na co zwrócić szczególną uwagę, starałam się użyć maksymalną ilość słów z danej dziedziny. Wszystkie dyktanda z kursu "Zaawansowana terminologia" są napisane wg schematu epikryzy wypisowej pacjenta, które też są wynikiem mojej wyobraźni. Starałam się obciążyć moich wyobrażonych pacjentów jak najliczniejszymi schorzeniami i objawami, żeby poszerzyć Państwa wiedzę w terminologii medycznej i dać wgląd na to, jak będzie wyglądała wasza praca, ponieważ niedługo sami będzie pisać podobne epikryzy.',
        ['Kursy "Podstawa terminologii" i "Zaawansowana terminologia" nie zawierają żadnych informacji, które można ściągnąć bezpłatnie w internecie. Powszechnie dostępnych informacji i dużą ilość książek proszę szukać w grupie ', <a href="https://www.facebook.com/groups/291270561038032/" rel="noopener noreferrer" target="_blank">Nostryfikacja dyplomu medycznego w Polsce</a>,'.'],
        'Nie, nie dajemy żadnych gwarancji.',
        'Nie, nie dajemy żadnych gwarancji.'
      ],
      extended: []
    };
  }
  toogleAnswerVisibility = (idx) => {
    let extended = Object.assign([], this.state.extended);
    extended[idx] = !this.state.extended[idx];
    this.setState({extended});
  }
  render() {
    const { answers, questions, extended } = this.state;
    const questionsAndAnswers = questions.map((question, idx) =>
      <li className="faq-question-answer-container" key={idx} >
        <div
          className="faq-question"
          onClick={() => this.toogleAnswerVisibility(idx)}>
          { question }
          <i className={classNames(['fa', {'fa-caret-down': !extended[idx], 'fa-caret-up': extended[idx]}])}></i>
        </div>
        <TransitionGroup className='faq-answer-container'>
          <CSSTransition
            key={extended[idx]}
            timeout={300}
            classNames='faq-slide-top'>
            <div className='faq-absolute-wrapper'>
              {
                extended[idx] &&
                <div className="faq-answer">
                  { answers[idx] }
                </div>
              }
            </div>
          </CSSTransition>
        </TransitionGroup>
      </li>
    );
    return (
      <section aria-label="FAQ" id="faq">
        <div className="faq-content">
          <div className="faq-title">
            <i className='fa fa-3x fa-question-circle'></i>
            <h2>FAQ, CZYLI NAJCZĘŚCIEJ ZADAWANE PYTANIA</h2>
          </div>
          <ul className="faq-questions-answers-list">
            {
              questionsAndAnswers
            }
          </ul>
        </div>
      </section>
    );
  }
}

export default Faq;
