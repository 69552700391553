import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://dyktandolekarza.pl'
})

try {
  const accessToken = window.sessionStorage.getItem('accessToken')
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
} catch (err) {
  // console.log(err)
}

const authorizeInterceptor = instance.interceptors.response.use(res => {
  // console.log('interceptor', res)
  return res
}, err => {
  const originalRequest = err.config;
  let refreshToken;
  if (err.response.status === 401) {
    try {
      refreshToken = window.localStorage.getItem('refreshToken');
      // console.log(err.response.data.error.message);
      // console.log(err.response.data);
      if(err.response.data.error.message === 'refresh token expired') {
        instance.interceptors.response.eject(authorizeInterceptor);
        return Promise.reject('refresh token expired');
      }
    } catch(err) {
      // console.log('!!!!', err);
    }
    return instance.post('/api/auth/refresh-tokens', {refreshToken})
      .then(res => {
        // console.log('refresh!', res);
        const accessToken = res.headers.authorization.split(' ')[1];
        const { refreshToken, refreshTokenExpiration } = res.data;
        window.sessionStorage.setItem('accessToken', accessToken);
        window.localStorage.setItem('refreshToken', refreshToken);
        // let refreshTokenExpirationDate = new Date();
        // refreshTokenExpirationDate.setSeconds(refreshTokenExpirationDate.getSeconds() + refreshTokenExpirationTime);
        window.localStorage.setItem('refreshTokenExpiration', refreshTokenExpiration);
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
        return axios(originalRequest);
      })
      .catch(error => {
        // console.log(error);
        // instance.interceptors.response.eject(authorizeInterceptor);
        return Promise.reject(error);
      });
  }
  return Promise.reject(err);
});

export default instance
