import React, { Component } from 'react';

import './ResetPassword.scss';
import '../../shared/styles/Forms.scss';
import axiosDyktando from '../../utils/axiosDyktando.js';
import Navigation from '../../shared/components/navigation/Navigation.jsx';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pending: false,
      success: false,
      error: ''
    };
  }
  resetPassword = ev => {
    ev.preventDefault();
    this.setState({
      pending: true,
      error: ''
    });
    axiosDyktando.post('/api/auth/reset-password', {email: this.state.email})
      .then(res => {
        console.log(res);
        this.setState({
          success: true,
          pending: false
        });
      })
      .catch(err => {
        if(err.response.data.error.message === 'Email not found') {
          this.setState({
            error: 'Wprowadzony adres e-mail nie jest powiązany z żadnym kontem.',
            success: false,
            pending: false
          });
        } else {
          this.setState({
            error: 'Coś poszło nie tak, spróbuj ponownie później.',
            success: false,
            pending: false
          });
        }
      });
  }
  onInputChange = e => {
    this.setState({[e.target.name]: e.target.value});
  }
  render() {
    return (
      <div className='wrapper'>
        <Navigation history={this.props.history} />
        <div className="reset-password-container">
          <h3> Odzyskaj hasło </h3>
          <h4> Wyślemy do Ciebie wiadomość e-mail z instrukcją resetowania hasła. </h4>
          <form onSubmit={this.resetPassword}>
            <input name="email" type="email" placeholder="Twój e-mail" onChange={this.onInputChange} />
            <div className="error-message">
              {
                this.state.error.length > 0 &&
                this.state.error
              }
            </div>
            <button type="submit">
              {
                this.state.pending ?
                  <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                  :
                  this.state.success ?
                    'Wiadomość wysłana' :
                    'Wyślij'
              }
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
