import Validator from 'validator';

const validateInput = data => {
  let formData = Object.assign({}, data);
  let errors = formData.errors;
  const email = formData.email;
  const password = formData.password;
  const passwordConfirmation = formData.passwordConfirmation;

  //validate email
  if(errors.email !== 'Użytkownik o tym adresie e-mail już istnieje w bazie') {
    errors.email = '';
    if (typeof email !== 'undefined' && Validator.isEmpty(email)) {
      errors.email = 'To pole jest obowiązkowe';
    } else if (typeof email !== 'undefined' && !Validator.isEmail(email)) {
      errors.email = 'Adres e-mail jest niepoprawny';
    }
  }

  //validate password
  errors.password = '';
  if (typeof password !== 'undefined' && Validator.isEmpty(password)) {
    errors.password = 'To pole jest obowiązkowe';
  } else if (typeof passwordConfirmation !== 'undefined' && Validator.isEmpty(passwordConfirmation)) {
    errors.passwordConfirmation = 'To pole jest obowiązkowe';
  }
  errors.passwordConfirmation = '';
  if (typeof password !== 'undefined' && typeof passwordConfirmation !== 'undefined' && !Validator.equals(data.password, data.passwordConfirmation)) {
    errors.passwordConfirmation = 'Hasła muszą się zgadzać';
  } else if(typeof password !== 'undefined' && password.length < 6) {
    errors.password = 'Hasło musi mieć przynajmniej 6 znaków';
  }

  return {
    errors,
    isValid: Object.keys(errors).every(key => errors[key] === '')
  };
};

export default validateInput;
