import React from 'react';
import classNames from 'classnames';

import './ShopOffer.scss';

const ShopOffer = props => {
  return (
    <div className="shop-offer">
      <div className="shop-offer-title"> { props.title } </div>
      <div className="shop-offer-container">
        <div className="shop-offer-duration">
          <span>Czas dostępu do kursu:</span>
          <label>
            <input type="radio" value="30"
              checked={props.value === '30'}
              onChange={props.handleChange} />
            30 dni – 89zł
          </label>
          <label>
            <input type="radio" value="60"
              checked={props.value === '60'}
              onChange={props.handleChange} />
            60 dni – 129zł
          </label>
        </div>
        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          {
            !props.shouldShowCoupon ?
              <div className="coupon" onClick={props.showCoupon}> Masz kupon? </div>
              :
              props.coupon.promotion > 0 ?
                <div className="coupon-accept">
                  Kupon ze zniżką -{ props.coupon.promotion }% został dodany!
                </div>
                :
                <div className="coupon-input-wrapper">
                  <form className="coupon-input-container" onSubmit={e => { e.preventDefault(); }} >
                    <input type="text"
                      className={classNames('coupon-input',
                        {'coupon-input-error': props.coupon.error}
                      )}
                      onChange={props.handleTextInputChange} />
                    <div className="coupon-add" onClick={props.addCoupon}>Dodaj</div>
                  </form>
                  <p className="coupon-error">
                    {
                      props.coupon.error.length > 0 &&
                      props.coupon.error
                    }
                  </p>
                </div>
          }
        </div>
        <div className="shop-offer-price">
          Cena:
          <span>
            {
              ' ' + Math.round(props.duration[props.value] * (1 - (props.coupon.promotion / 100)))
               + 'zł'
            }
          </span>
        </div>
      </div>
      <div className="paypal-button" id="paypal-button-container"></div>
      <div className="privat">
        если вы хотите заплатить посредством Приват24, напишите нам на dyktandolekarza@gmail.com
      </div>
    </div>
  );
};

export default ShopOffer;

